import { useState } from "react";
import { useAuth } from "../common/AuthApi";
import { useAuthManager } from '../common/AuthManager';
import logo from '../imgs/icono_4.png'; 
import fondo from '../imgs/fondo.jpg';

const Login = () => {
    const { setAuthInfo } = useAuth();
    const [cred, setCred] = useState({"email": "", "password": "" });
    const [notice, setNotice] = useState(""); 
    const [loading, setLoading] = useState(false);
    const AuthManager = useAuthManager(setAuthInfo);

    const onChange = (e) => {
        setCred(prev => {
            return {...prev, [e.target.name]: e.target.value }
        });
    }

    const handle_form = async (e) => {
        e.preventDefault();
        setNotice("");
        setLoading(true);
        await AuthManager.login(cred.email, cred.password, setNotice);
        setLoading(false);
    };

    const fondo_style = {
        backgroundImage: `url(${fondo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom'
    };

    return (
        <div style={{ fontSize: "0.9em" }} >
            <div className="d-flex align-items-center justify-content-center vh-100" 
                style={fondo_style}
            >
                <form onSubmit={(e) => handle_form(e)}>
                    <div className="rounded border border-dark px-3 py-2 shadow" style={{ backgroundColor: "#fbfbfb", width: 'min(500px, 100vw)' }}>
                        <div className="row">
                            <div className="col-md-5 text-center py-2">
                                <img src={logo} alt="" style={{ width: '100%' }} />
                            </div>

                            <div className="col-md-7 px-3 pt-2">                            
                                <div className="mb-2">
                                    <p className="h3 fw-bold">Sistema Inmobiliario</p>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="email" className="form-label">Usuario:</label>
                                    <input type="email" id="email" name="email" className="form-control form-control-sm" 
                                        placeholder="email Ej. usuario@gmail.com" onChange={ (e) => onChange(e)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="pass" className="form-label">Contraseña:</label>
                                    <input type="password" id="pass" name="password" className="form-control form-control-sm" 
                                        placeholder="*********" onChange={ (e) => onChange(e)} required />
                                </div>
                                <div className="mb-2 text-center">
                                    <button className="btn btn-dark btn-sm w-75" disabled={loading}>
                                        {(loading) && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>  }
                                        Ingresar
                                    </button>
                                </div>

                                {(notice) && 
                                    <div className="alert alert-danger py-1 text-center">
                                        {notice}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default Login;