import { useState } from "react";
import { useApi } from "../common/Api";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";

const Form = ({ item ,setShowForm, refreshTable, showSnackBar }) => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const [formData, setFormData] = useState(item);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const close = () => {
        setShowForm(false);
    }

    const OnChangeForm = (e) => {
        if(e.target.type === 'checkbox') {
            setFormData(prev => {return {...prev, [e.target.name]: e.target.checked}; })
        } else { setFormData(prev => {return {...prev, [e.target.name]: e.target.value}; }) }
    }

    const handle_form = async (e) => {
        e.preventDefault();

        setLoading(true);
        setErrors({});
        if(formData.id > 0) { updateItem(formData.id); }
        else { saveItem(); }
        setLoading(false);
    }

    const saveItem = async () => {
        return Api.post("/api/usuarios", formData)
            .then(response => {
                let {data} = response;
                setFormData((prev) => { return {...prev, ...data} } );
                showSnackBar("success", "¡Item guardado correctamente!");
                if(refreshTable) { refreshTable(); }
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setErrors(data); }
                else { showSnackBar("error", `API Error Code: ${status}`); }
            })
    }

    const updateItem = async (id) => {
        return Api.put(`/api/usuarios/${id}`, formData)
            .then(response => {
                let {data} = response;
                setFormData((prev) => { return {...prev, ...data} } );
                showSnackBar("success", "¡Item actualizado correctamente!");
                if(refreshTable) { refreshTable(); }
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setErrors(data); }
                else { showSnackBar("error", `API Error Code: ${status}`); }
            })
    }

    const FormHeader = () => {
        return (
            <div className="form-header">
                <div className="p-1">
                    <span className="fas fa-inbox px-2"></span>
                    Formulario
                </div>
                <button className="btn btn-sm py-0 px-3 m-1 btn-danger"
                    onClick={() => close()}>
                    <span className="fas fa-xmark"></span>
                </button>
            </div> 
        );
    }

    const FormNotice = ({field}) => {
        return (
            (errors[field]) && 
                <div className="form-notice mt-1">
                    <span className="fas fa-xmark text-danger me-1"></span>
                    <span className="text-danger ">{errors[field]}</span>
                </div>
        );
    }

    return (
        <div className="container-form">
            <div className="form" >
                <FormHeader />
                <div className="py-2 px-4">
                    <div>
                        <div className="mb-2">
                            Introduzca la información correcta:
                        </div>
                        <form onSubmit={(e) => handle_form(e)}>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="nombre">Nombre</label>
                                        <input type="text" className="form-control form-control-sm" id="nombre" name="nombre" placeholder="Ej. Luis" 
                                            value={formData?.nombre} onChange={ (e)=> OnChangeForm(e)} required/>
                                        <FormNotice field="nombre" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="nombre">Tipo</label>
                                        <select className="form-select form-select-sm" name="tipo" id="tipo"
                                            value={formData?.tipo} onChange={ (e)=> OnChangeForm(e)} required>
                                            <option value="">-- Seleccione una opción --</option>
                                            <option value="admin">Administrador</option>
                                            <option value="usuario">Vendedor</option>
                                        </select>
                                        <FormNotice field="tipo" />
                                    </div>
                                </div>                            
                            </div>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="nombre">Email</label>
                                        <input type="email" className="form-control form-control-sm" id="email" name="email" placeholder="email" 
                                            value={formData?.email} onChange={ (e)=> OnChangeForm(e)} required/>
                                        <FormNotice field="email" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="nombre">Password</label>
                                        <input type="password" className="form-control form-control-sm" id="password" name="password" placeholder="Contraseña" 
                                            value={formData?.password} onChange={ (e)=> OnChangeForm(e)} required />
                                        <FormNotice field="password" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="nombre">Teléfono</label>
                                        <input type="text" className="form-control form-control-sm" id="contacto" name="contacto" placeholder="contacto" 
                                            value={formData?.contacto} onChange={ (e)=> OnChangeForm(e)}/>
                                        <FormNotice field="contacto" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>
                                        <label className="pe-2" htmlFor="nombre">Activo:</label>
                                        <input type="checkbox" className="" id="activo" name="activo"
                                            checked={formData?.activo} onChange={ (e)=> OnChangeForm(e)}/>
                                        <FormNotice field="email" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-sm btn-dark" style={{ width:'180px' }} disabled={loading}>
                                    {(loading) && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>  }
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;