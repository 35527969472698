import { memo, useState, useEffect } from "react";

const Table = (props) => {
    const {items, onDeleteItem} = props;
    const [subcampos, setSubcampos] = useState(items);

    useEffect(() => {
        if(items !== undefined) { setSubcampos(items); }
    }, [items]);

    const delete_item = (item) => {
        if(window.confirm("¿Eliminar item?")) {
            onDeleteItem(item);
        }
    }

    return (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <table className="table table-sm table-striped table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Valor</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    { (subcampos.length === 0)? (<tr><td colSpan={20} className="py-2">Sin items</td></tr>) : null }
                    {
                        (subcampos)?
                            subcampos.map(sc => (
                                <tr key={sc.id}>
                                    <td>{String(sc.id).padStart(3,'0') }</td>
                                    <td>{sc.valor}</td>
                                    <td className="text-center" style={{ width: '40px' }}>
                                        <button className="btn btn-sm py-0 px-1 btn-danger" title="eliminar..."
                                            onClick={() => {delete_item(sc)}}>
                                            <span className="fas fa-xmark"></span>
                                        </button>
                                    </td>
                                </tr>
                            )) : null
                    }
                </tbody>
            </table>
        </div>
    );
}

export default memo(Table);