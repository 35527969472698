import { useApi, api_base_url } from "../../common/Api";
import { useAuthManager } from "../../common/AuthManager";
import { useAuth } from "../../common/AuthApi";

export const useRegistros = () => {
    const { AuthInfo, setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const get = (params = {}) => {
        return Api.get(`${api_base_url}/api/crm_registros`, { params: params })
            .then(response => response)
            .catch(error => error.response);
    }

    const create = (data) => {
        return Api.post(`${api_base_url}/api/crm_registros`, data);
    }

    const update = (id, data) => {
        return Api.put(`${api_base_url}/api/crm_registros/${id}`, data);
    }

    const destroy = (id) => {
        return Api.delete(`${api_base_url}/api/crm_registros/${id}`);
    }

    const getUsuarios = () => {
        return Api.get(`${api_base_url}/api/usuarios?todos=si`);
    }

    const defaultState = {
        campos: [],
        items : [],
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "fill_items":
                return {...state, items: action.payload};
            case "fill_campos":
                return {...state, campos: action.payload};
            case "fill":
                const {items, campos} = action.payload;
                return {...state, campos: campos, items: items};

            case "add_item": 
                return {...state, items: [...state.items, action.payload]};
            case "update_item":
                return {...state, items: state.items.map(it => {
                    if(it.id === action.payload.id) { return action.payload } else { return it; }
                })}
            case "del_item":
                return {...state, items: state.items.filter(it => it.id !== action.payload)}
            default: return state;
        }
    }

    return {get, create, update, destroy, reducer, defaultState, AuthInfo, getUsuarios};
}