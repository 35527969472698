import { useState, useEffect } from "react";
import CrmSCMain from '../crm_subcampos/CrmSCMain';

const Form = (props) => {
    const {currentItem, saveItem, cancelEdit, formErrors} = props;
    
    const [item, setItem] = useState(currentItem);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if(currentItem !== undefined) { setItem(currentItem); }
        return () => { console.log("Desmontando Form."); };
    }, [currentItem]);

    useEffect(() => {
        const { errors } = formErrors;
        if(errors !== undefined) { setErrors(errors); }
    }, [formErrors]);

    const handle_change = (e) => {
        if(e.target.type === "checkbox") {
            setItem({...item, [e.target.name]: e.target.checked });
            return;
        }
        setItem({...item, [e.target.name]: e.target.value});
    }

    const handle_submit = (e) => {
        e.preventDefault();
        saveItem(item);
    }

    const FormHeader = () => {
        return (
            <div className="form-header">
                <div className="p-1">
                    <span className="fas fa-inbox px-2"></span>
                    Formulario
                </div>
                <button className="btn btn-sm py-0 px-3 m-1 btn-danger"
                    onClick={() => cancelEdit()}>
                    <span className="fas fa-xmark"></span>
                </button>
            </div> 
        );
    }

    return (
        <div className="container-form">
            <div className="form" >
                <FormHeader />
                <div className="py-2 px-4 form-body">
                    <div className="py-2">
                        <form onSubmit={(e) => handle_submit(e)} action="">
                            <div className="row mb-2">
                                <div className="col-md">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Nombre:</span>
                                        <input className="form-control form-control-sm" type="text" name="nombre" 
                                            defaultValue={item?.nombre} onChange={(e) => handle_change(e) } autoFocus />
                                    </div>
                                    <div className="text-danger" style={{ fontSize: '.75em' }}>
                                        { errors.nombre }
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Etiqueta:</span>
                                        <input className="form-control form-control-sm" type="text" name="etiqueta" 
                                            defaultValue={item?.etiqueta} onChange={(e) => handle_change(e) } />
                                    </div>
                                    <div className="text-danger" style={{ fontSize: '.75em' }}>
                                        { errors.etiqueta }
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md input-group input-group-sm">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">Tipo de dato:</span>
                                    <select name="tipo" id="tipo" className="form-select form-select-sm"
                                        defaultValue={item?.tipo} onChange={(e) => handle_change(e) } >
                                        <option value="texto">Texto</option>
                                        <option value="numerico">Numérico</option>
                                        <option value="seleccion">Selección Multiple</option>
                                        <option value="si_no">Si/No</option>
                                        <option value="fecha">Fecha</option>
                                        <option value="fecha_hora">Fecha y hora</option>
                                    </select>
                                </div>
                                <div className="col-md input-group input-group-sm">
                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                        Requerido:
                                        <input name="requerido" className="form-check-input mt-1 ms-2" type="checkbox" 
                                            defaultChecked={true && item?.requerido} onChange={(e) => handle_change(e) } />
                                    </span>
                                </div>
                            </div>
                            <div className="py-2 text-end">
                                <button type="submit" className="btn btn-sm btn-light border">
                                    <span className="fas fa-save me-1"></span>
                                    Guardar
                                </button>
                                <button type="button" className="btn btn-sm btn-light border ms-1"
                                    onClick={() => cancelEdit() } >
                                    <span className="fas fa-xmark me-1"></span>
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        {
                            (item.id > 0 && item.tipo === "seleccion")? 
                                (<CrmSCMain crm_campo={item} />) 
                            : null
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;