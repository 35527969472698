import { useState } from "react";

const Form = (props) => {
    const {campos, crm_registro_id, add_item} = props;
    const [data, setData] = useState({id: 0, crm_registro_id: crm_registro_id, campo_id: 0, dato: "" });
    const [selectedCampo, setSelectedCampo] = useState({});

    const handle_change = (e) => {
        if(e.target.name === "campo_id") { 
            let campo = campos.find(c => c.id === Math.ceil(e.target.value));
            setSelectedCampo(campo);
        }
        setData(prev => {return {...prev, [e.target.name]: e.target.value}});
    }

    const handle_submit = async (e) => {
        e.preventDefault();
        add_item(data);
        setData(prev => { return {...prev, campo_id: 0, dato: ""} });
    }

    return (
        <div>
            <form onSubmit={(e) => handle_submit(e)} action="">
                <label htmlFor="campo_id" className="form-label">Campo:</label>
                <select name="campo_id" id="campo_id" className="form-select form-select-sm"
                    value={data.campo_id}
                    onChange={(e) => handle_change(e)}
                    required
                >
                    <option value="">-- Seleccionar --</option>
                    {
                        campos.map(campo => {
                            return (
                                <option value={campo.id}>{campo.etiqueta}</option>
                            )
                        })
                    }
                </select>
                <label htmlFor="dato" className="form-label pt-1">Valor:</label>
                
                {
                    (selectedCampo && selectedCampo.tipo === "seleccion")? 
                        (
                            <select name="dato" id="dato" className="form-select form-select-sm"
                                value={data.dato}
                                onChange={(e)=>handle_change(e)}
                                required
                            >
                                <option value="">-- Seleccionar --</option>
                                {
                                    selectedCampo.subcampos.map(sc => {
                                        return (<option value={sc.valor}>{sc.valor}</option>)
                                    })                                
                                }
                            </select>
                        )
                    : null
                }

                {
                    (selectedCampo && selectedCampo.tipo === "si_no")? 
                        (
                            <select name="dato" id="dato" className="form-select form-select-sm"
                                value={data.dato}
                                onChange={(e)=>handle_change(e)}
                                required
                            >
                                <option value="">-- Seleccionar --</option>
                                <option value="si">Si</option>
                                <option value="no">No</option>
                            </select>
                        )
                    : null
                }

                {
                    (selectedCampo && selectedCampo.tipo === "numero")? 
                        (
                            <input id="dato" name="dato" type="number" min={0} max={999999999} className="form-control form-control-sm" 
                                value={data.dato}
                                onChange={(e)=>handle_change(e)}
                                required
                            />
                        )
                    : null
                }

                {
                    (selectedCampo && selectedCampo.tipo === "texto")? 
                        (
                            <input id="dato" name="dato" type="text" maxLength={500} className="form-control form-control-sm" 
                                value={data.dato}
                                onChange={(e)=>handle_change(e)}
                                required
                            />
                        )
                    : null
                }

                <div className="text-end pt-2">
                    <button className="btn btn-sm btn-dark px-3">
                        Agregar...
                    </button>
                </div>
            </form>
        </div>
    )
} 

export default Form;