import { memo } from "react";

const Table = (props) => {
    const {datos, del_item} = props;
    
    const delete_it = (it) => {
        if(window.confirm("¿Eliminar item?")) {
            del_item(it);
        }
    }

    return (
        <div className="table-responsive" style={{ maxHeight: '400px' }}>
            <table className="table table-sm table-bordered table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Campo</th>
                        <th>Valor</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (datos.length)?
                            (datos.map(it => {
                                return (
                                    <tr key={it.id}>
                                        <td className="text-end">{String(it.id).padStart(3,'0')}</td>
                                        <td>{it.campo?.etiqueta}</td>
                                        <td>{it.dato}</td>
                                        <td className="text-center" style={{ width: '40px' }}>
                                            <button className="btn btn-sm py-0 btn-danger"
                                                style={{ fontSize:'0.8em' }}
                                                onClick={() => delete_it(it)}
                                            >
                                                <span className="fas fa-xmark"></span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })) 
                        : (<tr><td colSpan={5}>Sin resultados...</td></tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default memo(Table);