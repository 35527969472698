import { useState, useEffect, memo } from "react";

const Table = (props) => {
    const {headers, data, editItem, showDelDialog} = props;
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        if(data !== undefined) {
            setCurrentData(data);
        }

        return () => { console.log("Desmontando componente Table."); }
    }, [data]);

    const MemoRow = memo(({row}) => {
        return (
            <tr>
                <td className="text-center" style={{ width: '100px' }}>
                    <button className="btn btn-sm btn-light border py-0 mx-2"
                        onClick={() => editItem(row)}>
                        <span className="fas fa-pen"></span>
                    </button>
                    <button className="btn btn-sm btn-danger py-0"
                        onClick={() => showDelDialog(row.id)}>
                        <span className="fas fa-trash"></span>
                    </button>
                </td>
                <td className="text-end" style={{ width: '80px' }}>
                    {String(row.id).padStart(5, '0')}
                </td>
                <td>{row.nombre}</td>
                <td>{row.etiqueta}</td>
                <td>{row.tipo}</td>
                <td className="text-center" style={{ width: '80px' }}>{ 
                    (row.requerido)? 
                        <span className="text-success">Si</span> :
                        <span className="text-warning">No</span> 
                }</td>
                <td>{row.dependencia}</td>
            </tr>
        );
    });

    const TableHeader = memo(() => {
        return (
            <tr>
                <th></th>
                {
                    headers.map(header => {
                        return (<th key={header}>{header}</th>);
                    })
                }
            </tr>
        )
    })

    return (
        <table className="table table-sm table-bordered">
            <thead>
                <TableHeader />
            </thead>
            <tbody>
                { 
                    currentData?.map(row => {
                        return (<MemoRow row={row} key={row.id} />)
                    })
                }
            </tbody>
        </table>
    );

}

export default memo(Table);