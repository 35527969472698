import Axios from 'axios';

export const api_base_url = "https://api.sistemainmuebles.com";
/* export const api_base_url = "http://localhost:8000"; */

export const useApi = (AuthManager = null) => {
    const axios = Axios.create({
        baseURL: api_base_url,
        withCredentials: true,
        headers: { 'Accept': 'application/json' }
    });

    axios.interceptors.response.use(response => response, error => {
        if(error.response.status === 401 || error.response.status === 419) { AuthManager?.clearInfo(); }
        return Promise.reject(error);
    });

    return axios;
};