import { NavLink, useLocation } from "react-router-dom";
import { useAuthManager } from "./AuthManager";
import { useAuth } from "./AuthApi";
import logo from '../imgs/icono_4.png'; 

const NavBar = () => {
    const { AuthInfo, setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const location = useLocation();

    const handle_logout = async () => {
        await AuthManager.logout();
    };

    return (
        <div className="">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <NavLink to="/" className="navbar-brand">
                        <img src={logo} alt="" className="me-2" style={{ width: '30px', marginTop: '-8px' }} />
                        Sistema Inmobiliario
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <div>
                            <ul className="navbar-nav">
                                
                            {
                                (AuthInfo.tipo === "admin")?
                                (<li className="nav-item">
                                    <NavLink to="/" className={ (navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} >
                                        Inicio
                                    </NavLink>
                                </li>)
                                : null
                            }

                            {
                                (AuthInfo.tipo === "admin")?
                                    (<li className="nav-item dropdown">
                                        <NavLink 
                                            className={() => ((['/usuarios','/campos','/upload-info'].indexOf(location.pathname) >= 0) ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toogle')}
                                            to="/\" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Gestionar
                                        </NavLink>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                            <li className="nav-item">
                                                <NavLink to="/usuarios" className={'dropdown-item'} >
                                                    Usuarios
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/campos" className="dropdown-item">Campos</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/upload-info" className="dropdown-item">Subir información Masiva    </NavLink>
                                            </li>
                                        </ul>
                                    </li>)
                                    : null
                            }

                                <li className="nav-item">
                                    <NavLink to="/inmuebles" className="nav-link">Inmuebles</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/mapa" className="nav-link">Mapa</NavLink>
                                </li>

                        {
                            (AuthInfo.tipo === "admin")?
                                (
                                <li className="nav-item dropdown">
                                    <NavLink 
                                        className={() => ((['/crm-registros','/crm-campos'].indexOf(location.pathname) >= 0) ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toogle')}
                                        to="/\" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Clientes (CRM)
                                    </NavLink>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                        <li className="nav-item">
                                            <NavLink to="/crm-campos" className="dropdown-item ">Campos</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/crm-registros" className="dropdown-item ">Registros</NavLink>
                                        </li>
                                    </ul>
                                </li>
                            )
                            : (
                                <li className="nav-item">
                                    <NavLink to="/crm-registros" className="nav-link ">Clientes</NavLink>
                                </li>
                            )
                        }
                            </ul>
                        </div>
                        <div className="d-flex">
                            <div className="d-flex py-1 px-2 text-center" style={{ fontSize:"0.8em" }}>
                                <div className="text-light px-2">
                                    Bienvenid@: <br/> 
                                    <b>{ AuthInfo.nombre }</b>
                                </div>
                                <button className="btn btn-sm btn-danger py-0" onClick={()=>handle_logout()} >
                                    [ Salir ]
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavBar;