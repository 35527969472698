import { useState, useRef } from "react";

const Form = (props) => {
    const {saveItem} = props;
    const valorRef = useRef();

    const [data, setData] = useState({valor:"", dependencia:""});

    const handle_change = (e) => {
        setData(prev=> {return {...prev, [e.target.name]: e.target.value}});
    }

    const handle_submit = async(e) => {
        e.preventDefault();
        await saveItem(data);
        setData({valor:"", dependencia:""});
        valorRef.current.value = "";
    }

    return (
        <div>
            <form action="#" onSubmit={(e) => handle_submit(e) }>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Valor:</span>
                    <input className="form-control form-control-sm" type="text" name="valor" 
                        defaultValue={data.valor} onChange={(e)=>handle_change(e)} ref={valorRef} required />
                </div>
                <div className="text-end">
                    <button className="btn btn-sm btn-dark px-4">
                        <span className="fas fa-save pe-1"></span>
                        Agregar
                    </button>
                </div>
            </form>
        </div>
    )

}

export default Form;