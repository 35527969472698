import moment from 'moment/moment';
import { useRegistros } from './useRegistros';
import { useCrmCampos } from '../crm_campos/useCrmCampos';
import { Link } from 'react-router-dom';
import Table from './Table';
import Form from './Form';
import { useCallback, useEffect, useState, useReducer, memo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar, Alert } from "@mui/material";
import ReactPaginate from 'react-paginate';

const RegistrosMain = () => {
    const CrmRegistros = useRegistros();
    const CrmCampos = useCrmCampos();

    const [headers, setHeaders] = useState([]);
    const [state, dispatch] = useReducer(CrmRegistros.reducer, CrmRegistros.defaultState);

    const [currentItem, setCurrentItem] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const [SBInfo, setSBInfo] = useState({open: false, type: "error", message: ""});
    const [delValue, setDelValue] = useState(0);
    const [openDelDialog, setOpenDelDialog] = useState(false);

    const [loading, setLoading] = useState(false);
    const [itemsInfo, setItemsInfo] = useState({});
    const [params, setParams] = useState({page: 1, sel_params: []});

    useEffect(() => {
        get_data();
        return () => { console.log("Desmontando componente CrmRegistrosMain."); }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(state.campos !== undefined) {
            let campos_headers = state.campos.map(it => it.etiqueta);
            setHeaders(["ID", "FECHA", "USUARIO", ...campos_headers]);
        }
    }, [state.campos])

    const get_data = async (t_params) => {
        try {
            setLoading(true);
            let res1 = await CrmCampos.get();            
            let res2 = await CrmRegistros.get(t_params);

            dispatch({type: "fill", payload: {campos: res1.data.data, items: res2.data.data}});

            const info = {
                currentPage: res2.data.current_page,
                lastPage: res2.data.last_page,
                perPage: res2.data.per_page,
                total: res2.data.total,
                pages: res2.data.last_page
            };
            setItemsInfo(info);
            

            setLoading(false);
        } catch (error) { console.log(error); }
    }

    const get_data2 = async (s_page, t_params = null, usuario = null) => {
        let u_params = {...params, page: s_page};
        if(t_params) { u_params = {...u_params, sel_params: t_params}; }

        setParams(u_params);

        try {
            setLoading(true);
            let res2 = await CrmRegistros.get({...u_params, sel_params: encodeURIComponent(JSON.stringify(u_params.sel_params)), usuario: usuario});
            dispatch({type: "fill_items", payload: res2.data.data});

            const info = {
                currentPage: res2.data.current_page,
                lastPage: res2.data.last_page,
                perPage: res2.data.per_page,
                total: res2.data.total,
                pages: res2.data.last_page
            };
            setItemsInfo(info);
            setLoading(false);
        } catch (error) { console.log(error); }
    }

    /* const getDataA = () => {
        CrmRegistros.get()
            .then(response => { if(response.data !== undefined) { setData(response.data); } })
            .catch(err => console.log(err));
    } */

    const change_page = (e) => {
        get_data2(e.selected + 1);
    }

    const saveItem = useCallback(async (item) => {
        setFormErrors({});
        if(item.id) {
            try {
                const response = await CrmRegistros.update(item.id, item);
                dispatch({type:"update_item", payload: response.data})
                setCurrentItem(response.data);
            } catch (err) { setFormErrors(err.response.data); }
        } else {
            try {
                const response = await CrmRegistros.create(item); 
                item.id = response.data;
                dispatch({type: "add_item", payload: response.data});
                setCurrentItem(response.data);
            } catch (err) {
                setFormErrors(err.response.data)  
            }
        }
        showSnackBar("success", "¡Operación completada!")
    }, [CrmRegistros])

    const cancelEdit = useCallback(() => {
        setCurrentItem(null);
    }, [])
 
    const newItem = useCallback(() => {
        setFormErrors({});

        let item = { id:0, fecha: moment().format(), usuario_id: 0, obs: "", datos: [], inm_datos: [] };

        state.campos.forEach(campo => {
            let defValue = null;
            switch (campo.tipo) {
                case "texto": defValue = ""; break;
                case "numero": defValue = 0; break;
                case "seleccion": defValue = ""; break;
                case "si_no": defValue = "no"; break;
                default: defValue = ""; break;
            }
            item = {...item, datos: [...item.datos, {id: 0, crm_registro_id: 0, crm_campo_id: campo.id, dato: defValue, }] };
        });

        setCurrentItem(item);
    }, [state.campos])

    const editItem = useCallback((item) => {
        setFormErrors({});
        setCurrentItem(item);
    }, [])

    const delItem = useCallback((id) => {
        CrmRegistros.destroy(id)
                .then(_ => { 
                    dispatch({type: "del_item", payload: id});
                    showSnackBar("success", "¡Item eliminado!");
                }).catch(err => setFormErrors(err.response.data));

        dispatch({type: "del_item", payload: id});

    }, [setFormErrors, CrmRegistros])

    const showSnackBar = (type, message) => {
        setSBInfo(prev => { return {...prev, open: true, type: type, message:message}});
    }

    const SnackBar = () => {
        return (
            <Snackbar 
                anchorOrigin={{ "vertical": "bottom", "horizontal":"right" }}
                open={SBInfo.open}
                autoHideDuration={6000}
                onClose={ (e, reason) => { if(reason === "clickaway") { return; } setSBInfo(prev => { return {...prev, open:false } }); } }
                key={ "center + top" }
                TransitionProps={{ appear: false }}
                >
                <Alert severity={SBInfo.type} >{SBInfo.message}</Alert>
            </Snackbar>
        );
    }

    const DeleteDialog = () => {        
        return (
            <Dialog open={openDelDialog}
                onClose={null}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"¿Eliminar Item?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El item y todo los datos relacionados con el mismo se eliminarán definitivamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDelDialog(true) }>OK</Button>
                    <Button onClick={() => closeDelDialog(false) } autoFocus>Cancelar</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const showDelDialog = useCallback((item_id) => {
        setDelValue(item_id);
        setOpenDelDialog(true);
    }, [setDelValue, setOpenDelDialog]);

    const closeDelDialog = (res) => {
        if(res) { delItem(delValue); }
        setOpenDelDialog(false);
        setDelValue(0);
    }

    const AdvancedSearch = memo((props) => {
        const { get_items, s_params } = props;

        const [buscar, setBuscar] = useState("");
        const [selCampo, setSelCampo] = useState("");
        const [selParams, setSelParams] = useState(s_params);

        useEffect(() => {
            if(selParams) {
                //get_items({...params, ...selParams});
            }
        }, [selParams])

        const on_change_buscar = (e) => {
            setBuscar(e.target.value);
        }

        const on_change_campo = (e) => {
            setSelCampo(e.target.value);
        }

        const add_params  = () => {
            if(buscar && selCampo) {
                let ex = selParams.find(sp => sp.campo === selCampo);
                let sel_params = [];
                if(ex) {
                    sel_params = selParams.map(p => {
                            if(ex.campo === p.campo) { return {...ex, valor: buscar}; }
                            else { return p; }
                        }
                    );
                } else { sel_params = [...selParams, { campo: selCampo, valor: buscar}]; }

                setSelParams(sel_params);
                setBuscar("");
                setSelCampo("");

                get_items(sel_params);
            }
        }

        const del_param = (campo) => {
            let sel_params = selParams.filter(it => it.campo !== campo.campo);
            setSelParams(sel_params);
            get_items(sel_params);
        }

        return (
            <div>
                <div className="input-group input-group-sm my-1 ms-2" style={{ width: 'auto' }}>
                    <span className="input-group-text" id="inputGroup-sizing-sm">Buscar:</span>
                    <input type="text" size={40} className="form-control" 
                        value={buscar}
                        onChange={(e) => on_change_buscar(e)}
                    />
                    <select name="" id="" className="form-select"
                        value={selCampo}
                        onChange={(e) => on_change_campo(e)}
                    >
                        <option value="">-- Seleccionar --</option>
                        {
                            state.campos.map(cmp => {
                                return (
                                    <option key={cmp.id} value={cmp.nombre}>{cmp.etiqueta}</option>
                                )
                            })
                        }
                    </select>
                    <button className="btn btn-sm btn-light border"
                        onClick={() => add_params()}
                    >
                        Agregar...
                    </button>
                   
                </div>
                <div className='d-flex py-1 px-2' style={{ fontSize: '0.8em' }}>
                    {
                        selParams.map(sp => {
                            return (
                                <div key={sp.campo} className="px-1 py-0 my-0 me-1 bg-light border rounded">
                                    <span className="fw-bold">{sp.campo}:</span>
                                    <span className="ms-1">{sp.valor}</span>
                                    <button className="text-danger btn btn-sm ms-1 py-0 px-1" style={{ fontSize: '1em' }}
                                        onClick={() => del_param(sp)}
                                    >
                                        <span className="fas fa-xmark"></span>
                                    </button>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>

        );
    })

    return (
        <div className="container main-content">
            <div className="py-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" >
                                <span className="fas fa-home pe-1"></span>
                                Principal
                            </Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">CRM Registros</li>
                    </ol>
                </nav>
            </div>
            {
                (currentItem)? 
                    (<Form 
                        currentItem={currentItem} 
                        saveItem={saveItem} 
                        cancelEdit={cancelEdit}
                        formErrors={formErrors}
                        campos={state.campos}
                    />) 
                : null
            }
            <div>
                <div className='d-flex'>
                    <div>
                        <button type='button' className="btn btn-sm btn-light border my-1" onClick={newItem}>
                            <span className="fas fa-plus me-1"></span>
                            Agregar...
                        </button>
                    </div>
                    <AdvancedSearch 
                        get_items={(params, usuario) => get_data2(1, params, usuario)} 
                        s_params={params.sel_params}
                    />
                </div>
                <Table 
                    headers={headers} 
                    data={state.items} 
                    editItem={editItem}
                    showDelDialog={showDelDialog}
                    campos={state.campos}
                    loading={loading}
                >
                </Table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Siguiente"
                    previousLabel="Anterior"
                    renderOnZeroPageCount={null}
                    pageCount={itemsInfo.pages}
                    pageRangeDisplayed={itemsInfo.perPage}
                    onPageChange={(e) => {change_page(e)}}
                    containerClassName="pagination pagination-sm"
                    pageLinkClassName='page-link'
                    breakClassName='page-item'
                    nextClassName='page-item'
                    nextLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    activeLinkClassName='page-item active'
                    forcePage={  (itemsInfo.currentPage)? Math.max((itemsInfo.currentPage - 1), 0) : 0 }
                />
            </div>
            <DeleteDialog />
            <SnackBar />
        </div>
    );
}

export default RegistrosMain;