import { useEffect, useState } from "react";
import { useInmDatos } from "./useInmDatos";
import Table from "./Table";
import Form from "./Form";

const CrmInmMain = (props) => {
    const {crm_registro_id} = props;
    const [items, setItems] = useState([]);
    const [campos, setCampos] = useState([]);
    const InmDatos = useInmDatos();

    useEffect(() => {
        if(crm_registro_id) {
            InmDatos.get(crm_registro_id)
                .then(({data}) => setItems(data))
                .catch(err => console.log(err));
            InmDatos.getCampos()
                .then(({data}) => setCampos(data))
                .catch(err => console.log(err));
        }
        return () => { console.log("Desmontando CrmInmMain."); }
        // eslint-disable-next-line
    }, [crm_registro_id]);

    const add_item = async(item) => {
        try {
            const {data} = await InmDatos.create(crm_registro_id, item);
            if(data) {
                item.id = data.id;
                item.campo = data.campo;
                setItems(prev => [...prev, item]);
            }
        } catch (error) { console.log(error); }
    }

    const del_item = async(item) => {
        try {
            await InmDatos.destroy(crm_registro_id, item.id);
            setItems(prev => prev.filter(it => it.id !== item.id));
        } catch (error) { console.log(error); }
    }

    return (
        <div className="row">
            <div className="col-md">
                <Table datos={items} del_item={del_item} />
            </div>
            <div className="col-md">
                <Form campos={campos} crm_registro_id={crm_registro_id} add_item={add_item} />
            </div>
        </div>
    )
}

export default CrmInmMain;