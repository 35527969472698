import { useState, useEffect } from "react";
import { useApi } from "../common/Api";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";

const Form = ({ item ,setShowForm, refreshTable, showSnackBar, campos, getDefaultData }) => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const [formData, setFormData] = useState(item);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [formData2, setFormData2] = useState(() => getDefaultData());
    const [formDep, setFormDep] = useState([]);
    const [imagenes, setImagenes] = useState([]);

    useEffect(() => {
        setDependents();
        // eslint-disable-next-line
    }, []);

    const setDependents = () => {
        let dependents = [];
        campos.forEach(campo => {
            if(campo.dependencia) { dependents.push({ parent: campo.dependencia, child: campo.nombre }); }
        });
        setFormDep(dependents);
    }

    const updateImagenes = (e) => {
        setImagenes(e.target.files);
    }

    const uploadImages = (id) => {
        let fd = new FormData();
        Array.from(imagenes).forEach(item => { fd.append(item.name, item); });
        return Api.post(`/api/inmuebles/${id}/upload-images`, fd, {
            headers: { 'Content-Type': 'multipart/form-data'}
        }).then(_ => { setImagenes([]); }).catch(_ => { });
    }

    const close = () => {
        setShowForm(false);
    }

    const OnChangeForm = (e) => {
       if(e.target.type === "checkbox") {
            setFormData(prev => {return {...prev, [e.target.name]: e.target.checked}; })
        } else {
            setFormData(prev => {return {...prev, [e.target.name]: e.target.value}; })
        }
    }

    const OnChangeForm2 = (e) => {
        let deps = formDep.filter(fd => fd.parent === e.target.name);
        let data = null;
        if(e.target.type === "checkbox") {
            data = {...formData2, [e.target.name]: e.target.checked};
        } else { data = {...formData2, [e.target.name]: e.target.value}; }

        if(deps) { deps.forEach(dp => { data = {...data, [dp.child]: ""}; }) }
        setFormData2(data);
    }

    const handle_form = async (e) => {
        e.preventDefault();
        setLoading(true);

        setErrors({});
        if(formData.id > 0) { await updateItem(formData.id); }
        else { await saveItem(); }

        /* uploadImages(formData.id); */

        setLoading(false);
    }

    const saveItem = async () => {  
        return Api.post("/api/inmuebles", {data: formData, ad_data: formData2})
            .then(async (response) => {
                let {data} = response;
                setFormData((prev) => { return {...prev, ...data} } );
                showSnackBar("success", "¡Item guardado correctamente!");

                await uploadImages(data.id);
                if(refreshTable) { refreshTable(); }
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setErrors(data); }
                else { showSnackBar("error", `API Error Code: ${status}`); }
            });
    }
    
    const updateItem = async (id) => {
        return Api.put(`/api/inmuebles/${id}`, {data: formData, ad_data: formData2, imagenes: imagenes})
            .then(async (response) => {
                let {data} = response;
                setFormData((prev) => { return {...prev, ...data} } );
                showSnackBar("success", "¡Item actualizado correctamente!");
                await uploadImages(data.id);
                if(refreshTable) { refreshTable(); }
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setErrors(data); }
                else { showSnackBar("error", `API Error Code: ${status}`); }
            });
    }

    const FormHeader = () => {
        return (
            <div className="form-header">
                <div className="p-1">
                    <span className="fas fa-inbox px-2"></span>
                    Formulario
                </div>
                <button className="btn btn-sm py-0 px-3 m-1 btn-danger"
                    onClick={() => close()}>
                    <span className="fas fa-xmark"></span>
                </button>
            </div> 
        );
    }

    const FormNotice = ({field}) => {
        return (
            (errors[field]) && 
                <div className="form-notice mt-1">
                    <span className="fas fa-xmark text-danger me-1"></span>
                    <span className="text-danger ">{errors[field]}</span>
                </div>
        );
    }

    return (
        <div className="container-form">
            <div className="form" >
                <FormHeader />
                <div className="py-2 px-4 form-body">
                    <div>
                        <div className="row mb-2">
                            <div className="col-md">
                                Introduzca la información correcta:
                            </div>
                            <div className="col-md-auto text-end" >
                                <span className="text-danger ps-1">(*)</span>:
                                Campos obligatorios.
                            </div>
                        </div>
                        <form onSubmit={(e) => handle_form(e)}>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div className="row">
                                        <div className="col-md-auto">
                                            <label htmlFor="codigo" className="form-label">
                                                Código
                                                <span className="text-danger ps-1">(*)</span>:
                                            </label>
                                        </div>
                                        <div className="col-md">
                                            <input type="text" className="form-control form-control-sm" id="codigo" name="codigo" placeholder="Ej. AF+VD" 
                                                value={formData.codigo?? ""} onChange={ (e)=> OnChangeForm(e)} required/>
                                            <FormNotice field="codigo" />
                                        </div>
                                    </div>
                                </div>              
                            </div>

                            <div className="col-md mb-3">
                                <div className="row mb-3">
                                    <div className="col-md">
                                        <label htmlFor="nombre">
                                            Latitud
                                            <span className="text-danger ps-1">(*)</span>:
                                        </label>
                                        <input type="number" className="form-control form-control-sm" id="lat" name="lat" placeholder="Ej. -17.376893" 
                                                    value={formData?.lat} onChange={ (e)=> OnChangeForm(e)} required/>
                                                <FormNotice field="lat" />
                                    </div>
                                    <div className="col-md">
                                        <label htmlFor="nombre">
                                            Longitud
                                            <span className="text-danger ps-1">(*)</span>:
                                        </label>
                                        <input type="number" className="form-control form-control-sm" id="lng" name="lng" placeholder="Ej. -66.160872" 
                                                    value={formData?.lng} onChange={ (e)=> OnChangeForm(e)} required/>
                                                <FormNotice field="lng" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-md-4" htmlFor="">
                                        Imagenes
                                    </label>
                                    <input type="file" className="col-md form-control form-control-sm"
                                        onChange={ (e) => updateImagenes(e) }
                                        multiple accept="image/png, image/gif, image/jpeg" />
                                </div>
                            </div>        

                            <hr/>
                            {
                                campos.map(campo => {
                                    let field;
                                    switch (campo.tipo) {
                                        case "numero":
                                            field = (
                                                <>
                                                    <label className="col-md-4" htmlFor={campo.nombre}>
                                                        { campo.etiqueta }
                                                        <span className="text-danger ps-1">(*)</span>
                                                    </label>
                                                    <input type="number" min="0" max="1000000000" step={.01}
                                                        className="form-control form-control-sm col-md"
                                                        name={campo.nombre} id={campo.nombre} value={formData2[campo.nombre]} 
                                                        onChange={(e)=>OnChangeForm2(e)} />
                                                </>
                                            );
                                            break;
                                        case "seleccion":
                                            field = (
                                                <>
                                                    <label className="pb-1 col-md-4" htmlFor={campo.nombre}>{ campo.etiqueta }</label>
                                                    <select name={campo.nombre} id={campo.nombre} 
                                                        className="form-select form-select-sm col-md" value={formData2[campo.nombre]} 
                                                        onChange={(e)=>OnChangeForm2(e)} required={campo.requerido}  >
                                                        <option value="">-- seleccionar --</option>
                                                        {
                                                            (campo.dependencia)?
                                                                campo.subcampos.filter(scamp => scamp.v_dependencia === formData2[campo.dependencia]).map(subcampo => {
                                                                    return (<option key={subcampo.id} value={subcampo.valor}>{subcampo.valor}</option>);
                                                                })
                                                            : campo.subcampos.map(subcampo => {
                                                                return (<option key={subcampo.id} value={subcampo.valor}>{subcampo.valor}</option>);
                                                            })
                                                        }
                                                    </select>
                                                </>
                                            );
                                            break;
                                        case "si_no":
                                            field = (
                                                <>
                                                    <label className="pb-1 col-md-4" htmlFor={campo.nombre}>{ campo.etiqueta }</label>
                                                    <select name={campo.nombre} id={campo.nombre} 
                                                        className="form-select form-select-sm col-md" value={formData2[campo.nombre]} 
                                                        onChange={(e)=>OnChangeForm2(e)} >
                                                        <option value="">-- Seleccionar --</option>
                                                        <option value="si">Si</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </>
                                            );
                                            break;
                                        case "texto":
                                            field = (
                                                <>
                                                    <label className="pb-1 col-md-4" htmlFor={campo.nombre}>{ campo.etiqueta }</label>
                                                    <input type="text" name={campo.nombre} id={campo.nombre} 
                                                        className="form-control form-control-sm col-md" maxLength={500} value={formData2[campo.nombre]} 
                                                        onChange={(e)=>OnChangeForm2(e)} />
                                                </>
                                            );
                                            break;

                                        case "fecha":
                                            field = (
                                                <>
                                                    <label className="pb-1 col-md-4" htmlFor={campo.nombre}>{ campo.etiqueta }</label>
                                                    <input type="date" name={campo.nombre} id={campo.nombre} 
                                                        className="form-control form-control-sm col-md" maxLength={500} value={formData2[campo.nombre]} 
                                                        onChange={(e)=>OnChangeForm2(e)} />
                                                </>
                                            );
                                            break;
                                        case "fecha_hora":
                                            field = (
                                                <>
                                                    <label className="pb-1 col-md-4" htmlFor={campo.nombre}>{ campo.etiqueta }</label>
                                                    <input type="datetime-local" name={campo.nombre} id={campo.nombre} 
                                                        className="form-control form-control-sm col-md" maxLength={500} value={formData2[campo.nombre]} 
                                                        onChange={(e)=>OnChangeForm2(e)} />
                                                </>
                                            );
                                            break;
                                        default: break;
                                    }
                                    return (
                                        <div key={campo.id} className="row mb-3">
                                            {field}
                                        </div>
                                    );
                                })
                            }

                            

                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-sm btn-dark" style={{ width:'180px' }} disabled={loading}>
                                    {(loading) && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>  }
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;