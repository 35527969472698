import { useState, useEffect, memo, useCallback } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CrmInmMain from "../crm_inm/crmInmMain";

const Form = (props) => {
    const {currentItem, saveItem, cancelEdit, formErrors, campos} = props;
    
    const [item, setItem] = useState(currentItem);
    const [errors, setErrors] = useState({});
    const get_datos = () => {
        let item_build = {};
        currentItem.datos.forEach(dt => {
            let campo = campos.find(cmp => cmp.id === dt.crm_campo_id);
            if(campo) {
                item_build = {...item_build, [campo.nombre]: dt.dato}
            }
        })
        return item_build;
    }
    const [datos, setDatos] = useState(() => get_datos());

    useEffect(() => {
        if(currentItem !== undefined) { 
            setItem(currentItem);
        }
        return () => { console.debug("Desmontando Form."); };
    }, [currentItem, campos]);

    useEffect(() => {
        const { errors } = formErrors;
        if(errors !== undefined) { setErrors(errors); }
    }, [formErrors]);

    const handle_change = useCallback((e) => {
        setDatos(prev => { return {...prev, [e.target.name]: e.target.value} });
    }, [setDatos])

    const handle_submit = (e) => {
        e.preventDefault();
        let datos_final = campos.map(cmp => {
            return {"id": 0, "crm_registro_id": 0, "crm_campo_id": cmp.id, "dato": datos[cmp.nombre]};
        });
        let item_df = {...item, datos: datos_final};
        setItem(item_df);
        saveItem(item_df);
    }

    const FormHeader = () => {
        return (
            <div className="form-header">
                <div className="p-1">
                    <span className="fas fa-inbox px-2"></span>
                    Formulario
                </div>
                <button className="btn btn-sm py-0 px-3 m-1 btn-danger"
                    onClick={() => cancelEdit()}>
                    <span className="fas fa-xmark"></span>
                </button>
            </div> 
        );
    }

    return (
        <div className="container-form">
            <div style={{ display: 'none' }}>{ JSON.stringify(errors) }</div>

            <div className="form" >
                <FormHeader />
                <div className="py-2 px-4 form-body">
                    <Tabs>
                        <TabList>
                            <Tab>Formulario</Tab>
                            <Tab>Preferencias</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="py-2">
                                <form onSubmit={(e) => handle_submit(e)}>
                                    {
                                        campos.map(campo => {
                                            switch (campo.tipo) {
                                                case "texto":
                                                    return(
                                                        <div key={campo.id}>
                                                            <label htmlFor={campo.nombre} className="form-label">
                                                                {campo.etiqueta}
                                                                {
                                                                    (campo.requerido)?<span className="text-danger ms-1">(*)</span> : null
                                                                }
                                                            </label>
                                                            <input key={campo.id} className="form-control form-control-sm" 
                                                                id={campo.nombre} name={campo.nombre} type="text" maxLength={500}
                                                                value={datos[campo.nombre]}
                                                                onChange={(e) => handle_change(e)}
                                                                required={campo.requerido}
                                                            />
                                                        </div>
                                                    );
                                                case "numerico":
                                                    return (
                                                        <div key={campo.id}>
                                                            <label htmlFor={campo.nombre} className="form-label">{campo.etiqueta}</label>
                                                            <input key={campo.id} className="form-control form-control-sm" 
                                                                id={campo.nombre} name={campo.nombre} type="number" 
                                                                min={0} max={999999999} 
                                                                value={datos[campo.nombre]}
                                                                onChange={(e) => handle_change(e)}
                                                                required={campo.requerido}
                                                            />
                                                        </div>
                                                    );
                                                case "seleccion":
                                                    return (
                                                        <div key={campo.id}>
                                                            <label htmlFor={campo.nombre} className="form-label">{campo.etiqueta}</label>
                                                            <select key={campo.id} className="form-select form-select-sm"
                                                                id={campo.nombre} name={campo.nombre}
                                                                value={datos[campo.nombre]}
                                                                onChange={(e) => handle_change(e)}
                                                                required={campo.requerido}
                                                            >
                                                                <option value="">-- Seleccionar --</option>
                                                                {
                                                                    campo.crm_subcampos.map(sc => {
                                                                        return (<option key={sc.id} value={sc.valor}>{sc.valor}</option>)
                                                                    }) 
                                                                }
                                                            </select>
                                                        </div>
                                                    );
                                                case "si_no":
                                                    return (
                                                        <div key={campo.id}>
                                                            <label htmlFor={campo.nombre} className="form-label">{campo.etiqueta}</label>
                                                            <select key={campo.id} className="form-select form-select-sm"
                                                                id={campo.nombre} name={campo.nombre}
                                                                value={datos[campo.nombre]}
                                                                onChange={(e) => handle_change(e)}
                                                                required={campo.requerido}
                                                            >
                                                                <option value="si">Si</option>
                                                                <option value="no">No</option>
                                                            </select>
                                                        </div>
                                                    );
                                                case "fecha":
                                                    return(
                                                        <div key={campo.id}>
                                                            <label htmlFor={campo.nombre} className="form-label">
                                                                {campo.etiqueta}
                                                                {
                                                                    (campo.requerido)?<span className="text-danger ms-1">(*)</span> : null
                                                                }
                                                            </label>
                                                            <input key={campo.id} className="form-control form-control-sm" 
                                                                id={campo.nombre} name={campo.nombre} type="date"
                                                                value={datos[campo.nombre]}
                                                                onChange={(e) => handle_change(e)}
                                                                required={campo.requerido}
                                                            />
                                                        </div>
                                                    );
                                                case "fecha_hora":
                                                    return(
                                                        <div key={campo.id}>
                                                            <label htmlFor={campo.nombre} className="form-label">
                                                                {campo.etiqueta}
                                                                {
                                                                    (campo.requerido)?<span className="text-danger ms-1">(*)</span> : null
                                                                }
                                                            </label>
                                                            <input key={campo.id} className="form-control form-control-sm" 
                                                                id={campo.nombre} name={campo.nombre} type="datetime-local"
                                                                value={datos[campo.nombre]}
                                                                onChange={(e) => handle_change(e)}
                                                                required={campo.requerido}
                                                            />
                                                        </div>
                                                    );
                                                default: return null;
                                            }
                                        })
                                    }
                                    <div className="py-2 text-end">
                                        <button type="submit" className="btn btn-sm btn-light border">
                                            <span className="fas fa-save me-1"></span>
                                            Guardar
                                        </button>
                                        <button type="button" className="btn btn-sm btn-light border ms-1"
                                            onClick={() => cancelEdit() } >
                                            <span className="fas fa-xmark me-1"></span>
                                            Cancelar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="bg-white" style={{ minHeight: '350px' }}>
                                {
                                    (item.id)? 
                                        <CrmInmMain crm_registro_id={item.id} />
                                    : (<div className="py-2">Guarde el registro para ver esta sección...</div>)
                                }
                            </div>
                        </TabPanel>
                    </Tabs>
                    
                    
                </div>
            </div>
        </div>
    );
}

export default memo(Form);