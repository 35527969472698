import { useState, memo } from "react";
import { useApi } from "../common/Api";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";

const Form = ({ item ,setShowForm, refreshTable, showSnackBar, campos }) => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const [formData, setFormData] = useState(item);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const close = () => {
        setShowForm(false);
        if(refreshTable) { refreshTable(); }
    }

    const OnChangeForm = (e) => {
        if(e.target.type === "checkbox") {
            setFormData(prev => {return {...prev, [e.target.name]: e.target.checked}; })
        } else {
            setFormData(prev => {return {...prev, [e.target.name]: e.target.value}; })
        }
    }

    const handle_form = async (e) => {
        e.preventDefault();

        setLoading(true);
        setErrors({});
        if(formData.id > 0) { updateItem(formData.id); }
        else { saveItem(); }
        setLoading(false);
    }

    const saveItem = async () => {
        return Api.post("/api/campos", formData)
            .then(response => {
                let {data} = response;
                setFormData((prev) => { return {...prev, ...data} } );
                showSnackBar("success", "¡Item guardado correctamente!");
               
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setErrors(data); }
                else { showSnackBar("error", `API Error Code: ${status}`); }
            })
    }

    const updateItem = async (id) => {
        return Api.put(`/api/campos/${id}`, formData)
            .then(response => {
                let {data} = response;
                setFormData((prev) => { return {...prev, ...data} } );
                showSnackBar("success", "¡Item actualizado correctamente!");
                if(refreshTable) { refreshTable(); }
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setErrors(data); }
                else { showSnackBar("error", `API Error Code: ${status}`); }
            })
    }

    const FormHeader = () => {
        return (
            <div className="form-header">
                <div className="p-1">
                    <span className="fas fa-inbox px-2"></span>
                    Formulario
                </div>
                <button className="btn btn-sm py-0 px-3 m-1 btn-danger"
                    onClick={() => close()}>
                    <span className="fas fa-xmark"></span>
                </button>
            </div> 
        );
    }

    const FormNotice = ({field}) => {
        return (
            (errors[field]) && 
                <div className="form-notice mt-1">
                    <span className="fas fa-xmark text-danger me-1"></span>
                    <span className="text-danger ">{errors[field]}</span>
                </div>
        );
    }

    const MemoSubCampos = memo(({campo}) => {
        return (<SubCampos campo={campo} />);
    });

    const SubCampos = ({campo}) => {
        const [sdata, setSdata] = useState({valor:"", v_dependencia:""});
        const [subcampos, setSubcampos] = useState(campo.subcampos);
        const [error, setError] = useState("");

        const onChange = (e) => {
            setSdata(prev => {return {...prev, [e.target.name]: e.target.value}; });
        }

        const handle_submit = async (e) => {
            e.preventDefault();

            await save_item();
            return;
            
        }

        const save_item = () => {
            return Api.post(`/api/campos/${campo.id}/subcampos`, sdata)
            .then(response => {
                let {data} = response;
                campo.subcampos = [...campo.subcampos, data];
                setSubcampos(campo.subcampos);
                setSdata({valor:"", v_dependencia:""});
                /* showSnackBar("success", "¡Subcampo creado!") */
            }).catch(error => {
                const { status, data } = error.response;
                if(status === 422) { setError(data.valor[0]); }
                else { /* showSnackBar("error", `API Error Code: ${status}`); */ }
            })
        }

        const del_item = (id) => {
            if(window.confirm("¿Eliminar item?")) {

                return Api.delete(`/api/campos/${campo.id}/subcampos/${id}`)
                .then(_ => {
                    campo.subcampos = subcampos.filter(x=>x.id !== id);
                    setSubcampos(campo.subcampos);
                    /* showSnackBar("success", "¡El ítem fue eliminado!") */
                }).catch(e => {
                    /* showSnackBar("success", `Error Code: ${e.response.status}`) */
                });
            }
        }

        return (
            <div className="p-2">
                <div className="mb-2">
                    SubCampos:
                </div>

                <div className="row">
                    <div className="col-md">
                        <div className="list-subcampos-container">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Valor</th>
                                    <th>Dependencia</th>
                                    <th style={{ width: "15px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (!subcampos.length)? <tr><td className="p-2" colSpan="20">Sin datos</td></tr> :
                                        subcampos.map(subcampo => {
                                            return (
                                                <tr key={subcampo.id}>
                                                    <td>{subcampo.valor}</td>
                                                    <td>{subcampo.v_dependencia}</td>
                                                    <td>
                                                        <button className="btn btn-sm border ms-2 py-0 my-1"
                                                            onClick={()=>del_item(subcampo.id)}>
                                                            <span className="fas fa-trash"></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className="col-md">
                        <form onSubmit={(e) => handle_submit(e)}>
                            <div className="mb-2">
                                <label htmlFor="" className="form-label">Valor:</label>
                                <input type="text" name="valor" className="form-control form-control-sm" maxLength={190}
                                    value={sdata.valor} onChange={(e) => onChange(e)} required />
                            <label htmlFor="" className="form-label">Dependencia:</label>
                                <input type="text" name="v_dependencia" className="form-control form-control-sm" maxLength={190}
                                    value={sdata.v_dependencia} onChange={(e) => onChange(e)} />
                            </div>
                            <div className="mb-2">
                                <button className="btn btn-sm btn-dark">Agregar</button>
                            </div>
                            <div className="text-danger">
                                {error}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-form">
            <div className="form" >
                <FormHeader />
                <div className="py-2 px-4 form-body">
                    <div>
                        <div className="mb-2">
                            Introduzca la información correcta:
                        </div>
                        <form onSubmit={(e) => handle_form(e)}>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="nombre">Nombre</label>
                                        <input type="text" className="form-control form-control-sm" id="nombre" name="nombre" placeholder="Ej. nombre_dato" 
                                            value={formData?.nombre} onChange={ (e)=> OnChangeForm(e)} required/>
                                        <FormNotice field="nombre" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="etiqueta">Etiqueta</label>
                                        <input type="text" className="form-control form-control-sm" id="etiqueta" name="etiqueta" placeholder="Ej. nombre_dato" 
                                            value={formData?.etiqueta} onChange={ (e)=> OnChangeForm(e)} required/>
                                        <FormNotice field="tipo" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div>
                                        <label htmlFor="tipo">Tipo de Dato</label>
                                        <select className="form-select form-select-sm" name="tipo" id="tipo"
                                            value={formData?.tipo} onChange={ (e)=> OnChangeForm(e)} required>
                                            <option value="">-- Seleccione una opción --</option>
                                            <option value="numero">Numérico</option>
                                            <option value="seleccion">Selección Multiple</option>
                                            <option value="si_no">Si/No</option>
                                            <option value="texto">Texto</option>
                                            <option value="fecha">Fecha</option>
                                            <option value="fecha_hora">Fecha y Hora</option>
                                        </select>
                                        <FormNotice field="tipo" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    {
                                        (formData.tipo === "seleccion") && 
                                            <>
                                                <label htmlFor="tipo">Dependencia</label>
                                                <select name="dependencia" id="dependencia" className="form-select form-select-sm"
                                                    value={formData?.dependencia?? ""} onChange={ (e)=> OnChangeForm(e)} >
                                                    <option value="">-- Seleccionar --</option>
                                                    {
                                                        campos.map(item => {
                                                            return (<option key={item.nombre} value={item.nombre}>{item.etiqueta}</option>)
                                                        })
                                                    }
                                                </select>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div>
                                        <label className="me-3" htmlFor="es_publico">Requerido:</label>
                                        <input type="checkbox" name="requerido" id="requerido" 
                                            checked={formData?.requerido} onChange={ (e)=> OnChangeForm(e)} />
                                        <FormNotice field="requerido" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>
                                        <label className="me-3" htmlFor="es_publico">Preferencia:</label>
                                        <input type="checkbox" name="es_preferencia" id="es_preferencia" 
                                            checked={formData?.es_preferencia && true} onChange={ (e)=> OnChangeForm(e)} />
                                        <FormNotice field="requerido" />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div>
                                        <label className="me-3" htmlFor="es_publico">Público (Visibilidad):</label>
                                        <input type="checkbox" name="es_publico" id="es_publico" 
                                            checked={formData?.es_publico} onChange={ (e)=> OnChangeForm(e)} />
                                        <FormNotice field="es_publico" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-sm btn-dark" style={{ width:'180px' }} disabled={loading}>
                                    {(loading) && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>  }
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                    
                    {
                        ((formData.id > 0) && (formData.tipo === "seleccion")) &&
                            <MemoSubCampos campo={formData} />
                    }
                    {/* <SubCampos campo={item} /> */}
                </div>
            </div>
        </div>
    );
}

export default memo(Form);