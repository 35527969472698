import { useApi, api_base_url } from "../../common/Api";
import { useAuthManager } from "../../common/AuthManager";
import { useAuth } from "../../common/AuthApi";

export const useInmDatos = () => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const get = (crm_registro_id) => {
        return Api.get(`${api_base_url}/api/crm_registros/${crm_registro_id}/inm_datos`);
    }

    const create = (crm_registro_id, data) => {
        return Api.post(`${api_base_url}/api/crm_registros/${crm_registro_id}/inm_datos`, data);
    }

    const update = (crm_registro_id, id, data) => {
        return Api.put(`${api_base_url}/api/crm_registros/${crm_registro_id}/inm_datos/${id}`, data);
    }

    const destroy = (crm_registro_id, id) => {
        return Api.delete(`${api_base_url}/api/crm_registros/${crm_registro_id}/inm_datos/${id}`);
    }

    const getCampos = () => {
        return Api.get(`${api_base_url}/api/campos`, {
            params: { todos: 'si', es_preferencia: '1' }
        });
    }

    return {get, create, update, destroy, getCampos};
}