import TableSC from "../crm_subcampos/Table";
import FormSC from "../crm_subcampos/Form";
import { useCrmSubCampos } from "./useCrmSubCampos";
import { useEffect, useState } from "react";

const CrmSCMain = (props) => {
    const {crm_campo} = props;
    const crmSubCampos = useCrmSubCampos();
    const [subcampos, setSubcampos] = useState([]);

    useEffect(() => {
        if(crm_campo) {
            crmSubCampos.get(crm_campo.id)
                .then(({data}) => setSubcampos(data))
                .catch(err=>console.log(err));
        }
        return () => { console.log("Desmontando TableCRMSubcampos."); }
        // eslint-disable-next-line 
    }, [crm_campo]);

    const save = async (item) => {
        try {
            const response = await crmSubCampos.create(crm_campo.id, item);
            const id = response.data.id;
            item.id = id;
            setSubcampos(prev => [...prev, item]);
            crm_campo.crm_subcampos = subcampos;
        } catch (error) { console.log(error) }

        /* crmSubCampos.create(crm_campo.id, item)
            .then(response => {
                const id = response.data.id;
                item.id = id;
                setSubcampos(prev => [...prev, item]);
                crm_campo.crm_subcampos = subcampos;
            }).catch(err => console.log(err)); */
    }

    const delItem = (item) => {
        crmSubCampos.destroy(crm_campo.id, item.id)
            .then(_=> {
                setSubcampos(prev => prev.filter(it => it.id !== item.id));
            }).catch(err => console.log(err));
    }

    return (
        <div className="row">
            <div className="col-md">
                <TableSC items={subcampos} onDeleteItem={delItem} />
            </div>
            <div className="col-md">
                <FormSC saveItem={save} />
            </div>
        </div>
    );
}

export default CrmSCMain;