import { useApi, api_base_url } from "../../common/Api";
import { useAuthManager } from "../../common/AuthManager";
import { useAuth } from "../../common/AuthApi";

export const useCrmCampos = () => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const get = () => {
        return Api.get(`${api_base_url}/api/crm_campos`)
            .then(response => response)
            .catch(error => error.response);
    }

    const create = (data) => {
        return Api.post(`${api_base_url}/api/crm_campos`, data);
    }

    const update = (id, data) => {
        return Api.put(`${api_base_url}/api/crm_campos/${id}`, data);
    }

    const destroy = (id) => {
        return Api.delete(`${api_base_url}/api/crm_campos/${id}`);
    }

    return {get, create, update, destroy};
}