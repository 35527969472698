import { useApi } from "./Api";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const defaultAuth = { nombre: "", tipo: "", isLogged: false };

export const readAuthCookie = () => {
    let dauth = Cookies.get('auth_data');
    if(dauth) { return JSON.parse(dauth); }
    else { return defaultAuth; }
}

export const useAuthManager = (setAuthInfo) => {
    const Api = useApi();
    const navigate = useNavigate();

    const login = async (email, password, setError = null) => { 
        return Api.get('/sanctum/csrf-cookie').then(async () => {
            return Api.post("api/login", { email: email, password: password }).then(response => {
                if(response.status === 200) {
                    const { nombre, tipo } = response.data;
                    const info = { "nombre": nombre, "tipo": tipo, "isLogged": true };
                    Cookies.set("auth_data", JSON.stringify(info), { sameSite: 'Lax' });
                    setAuthInfo(info);
                    navigate("/");
                }
            }).catch(error => {
                if(error.response.status === 401) {
                    if(setError) { setError(error.response.data.message); }
                }
            });
        });
    };

    const logout = async () => { 
        return Api.get("/api/logout")
            .then(_=> { clearInfo(); })
            .catch(e=>{});
    };

    const clearInfo = () => {
        setAuthInfo(defaultAuth);
        Cookies.remove("auth_data");
        navigate('/login');
    }

    const getUser = () => { };

    return {
        login: login,
        logout: logout,
        getUser: getUser,
        clearInfo: clearInfo
    };
};
