export const initialState = {
    loading: false,
    items: [],
    nombre: "",
    tipo: "",
    page: 1,
    pages: { current_page: 1, last_page: 1 },
    search: { nombre:"", page: 1 },
    ad_search : [],
    order_dir: 'asc',
    order_campo: 'id'
}

export const reducer = (state, action) => {
    let new_state = null;

    switch (action.type) {
        case "set_loading":
            new_state = {...state, loading: action.valor };
            break;
        case "set_nombre":
            new_state = {...state, nombre: action.valor };
            break;
        case "set_tipo":
            new_state = {...state, tipo: action.valor };
            break;
        case "set_items":
            new_state = {
                ...state, 
                items: action.valor.data, 
                pages: { current_page: action.valor.current_page, last_page: action.valor.last_page }, 
                search: {...state.search, page: action.valor.page },
                loading: false 
            };
            break;
        case "set_page":
            new_state = {...state, search: {...state.search, page: action.valor }};
            break;
        case "set_search_nombre":
            new_state = {...state, search: {...state.search, nombre: action.valor, page: 1 }};
            break;
        case "set_ad_search":
            new_state = {...state, ad_search: action.valor };
            break;
        case "set_ad_search_it":
            let filtered = state.ad_search.filter(item => item.nombre !== action.valor.nombre);
            let as = [...filtered, { nombre: action.valor.nombre, valor: action.valor.value }];
            new_state = {...state, ad_search: as };
            break;
        case "del_ad_search_it":
            let filtered2 = state.ad_search.filter(item => item.nombre !== action.valor);
            new_state = {...state, ad_search: filtered2 };
            break;
        case "set_order_dir":
            new_state = {...state, order_dir: action.valor }
            break;
        case "set_order_campo":
            new_state = {...state, order_campo: action.valor }
            break;
        default:
            new_state = state;
            break;
    }

    return new_state;
}