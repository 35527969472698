import { Link, Navigate } from "react-router-dom";
import { useAuth } from "./common/AuthApi";

const Main = () => {
    const { AuthInfo } = useAuth();
    
    if(AuthInfo.tipo === "usuario") {
        return (
            <Navigate to="/crm-registros" />
        )
    }

    return (
        <div className="container main-content">
            <div className="py-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active fw-bold">
                            <span className="fas fa-home pe-1"></span>
                            Principal
                        </li>
                    </ol>
                </nav>
            </div>

            <div className="">
                <p className="h5">Administración</p>
                <ul className="list1">
                    {
                        (AuthInfo.tipo === "admin") &&
                            <>
                                <li>
                                    <Link to="/usuarios">Administrar Usuarios</Link>
                                </li>
                                <li>
                                    <Link to="/campos">Administrar Campos</Link>
                                </li>
                                <li>
                                    <Link to="/crm-registros">Clientes</Link>
                                </li>
                            </>
                    }
                    <li>
                        <Link to="/inmuebles">Administrar Inmuebles</Link>
                    </li>
                    <li>
                        <Link to="/mapa">Mapa</Link>
                    </li>
                    <li>
                        <Link to="/crm-registros">Clientes</Link>
                    </li>

                    {
                        (AuthInfo.tipo === "admin") &&
                        <>
                            <li>
                                <Link to="/upload-info">Subir información Masiva</Link>
                            </li>
                        </>
                    }
                    
                </ul>

            </div>
        </div>
    );
}

export default Main;