import NavBar from "./NavBar";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <>
            <NavBar />
            <div className="container main-content">
                <div className="py-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/" >
                                    <span className="fas fa-home pe-1"></span>
                                    Principal
                                </Link>
                            </li>
                            <li className="breadcrumb-item active fw-bold" aria-current="page">404 - No encontrado.</li>
                        </ol>
                    </nav>
                </div>
                <p className="h3 text-center pt-3">
                    Página no encontrada. <br/>
                    Error <b>404</b> 🥲
                </p>
                <div className="text-center">
                    <Link to="/">&lt;&lt; Volver a inicio...</Link>
                </div>
            </div>
        </>
    );
};

export default NotFound;