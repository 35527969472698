import { useApi } from "../common/Api";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";
import { useState, useEffect, useReducer } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import Form from "./Form";
import { initialState, reducer } from "./inm_properties";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { api_base_url } from "../common/Api";
import { Carousel } from "react-responsive-carousel";
import DataTable from 'react-data-table-component';
import moment from 'moment';

const InmueblesMain = () => {
    const { AuthInfo, setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);
    const [showForm, setShowForm] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [openDelDialog, setOpenDelDialog] = useState(false);
    const [delValue, setDelValue] = useState(0);
    const [SBInfo, setSBInfo] = useState({open: false, type: "error", message: ""});
    const [campos, setCampos] = useState([]);
    const [search, setSearch] = useState({});

    const [state, dispatch] = useReducer(reducer, initialState);

    const [single, setSingle] = useState(null);

    useEffect(() => {
        getCampos();
        /* getItems(); */
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getItems();
        // eslint-disable-next-line
    }, [state.ad_search]);

    const getCampos = async () => {
        await Api.get('/api/campos', {
            params: {subcampos: "si", todos: "si"}
        }).then(response => {
            const { data } = response;
            setCampos(data);
        })
    }

    const updateSearch = (e) => {
        let reserved = ["f_fecha", "f_codigo"];
        let tipo = "texto";

        if(reserved.includes(e.target.value)){
            if(e.target.value === "f_fecha") { tipo = "fecha"; }
        } else {
            let campo = campos.find(item => item.nombre === e.target.value);
            if(campo) { tipo = campo.tipo; }
        }
        
        setSearch({...search, campo_nombre:e.target.value, campo_tipo: tipo });
    }

    const getInitialData = (tipo) => {
        let res = null;
        switch(tipo) {
            case "numero": res = 0; break;
            case "seleccion": res = ""; break;
            case "si_no": res = true; break;
            case "texto": res = ""; break;
            default: res = ""; break;
        }
        return res;
    }

    const getDefaultData = () => {
        let data = {};
        if(editItem.id > 0) {
            campos.forEach(campo => {
                let row = editItem.datos.find((dato) => dato.campo.id === campo.id);
                if(!row) { data = {...data, [campo.nombre]: getInitialData(campo.tipo)}; }
                else { data = {...data, [campo.nombre]: row.valor}; }
            });
        } else {
            data = campos.reduce((prev, item) => {
                let res = getInitialData(item.tipo);
                return {...prev, [item.nombre]: res };
            }, {});
        }
        return data;
    }

    const setSearchData = (e) => {
        if(e.target.name === "nombre") {
            if(e.code === "Enter" || e.code === "NumpadEnter") {
                dispatch({type: "set_ad_search_it", valor: { nombre: search.campo_nombre, value: e.target.value } });
            }
        }
    }

    const getItems = async (page = 1) => {
        /* setLoading(true); */
        dispatch({type: "set_loading", valor: true });

        await Api.get('/api/inmuebles', {params: {...state.search, ad_search: encodeURI(JSON.stringify(state.ad_search)), page: page}}).then(response => {
            const { data, current_page, last_page } = response.data;
            dispatch({type: "set_items", valor: {data,current_page,last_page, page} });

            //setItems(data);
            //setPages({ current_page, last_page  });
        }).catch(_=> {
            dispatch({type: "set_loading", valor: false });
        })

        /* setLoading(false); */
    }

    const getWidth = (tipo) => {
        switch (tipo) {
            case "texto": return '300px';              
            case "numero":
            case "seleccion":
            case "si_no": return '150px';
            default: return '150px';
        }
    }

    const Table = () => {
        
        let columns = [
            { name: "", grow: 2, cell: (row, index, column, id) => {
                return (
                    <>
                         {
                            (AuthInfo.tipo === 'admin') && 
                            <>
                                <button className="btn btn-sm btn-light border mx-2 py-0"
                                    onClick={() => { updateItem(row); }}>
                                    <span className="fas fa-pen"></span>
                                </button>
                                <button className="btn btn-sm btn-danger py-0"
                                    onClick={() => { showDelDialog(row.id) }}>
                                    <span className="fas fa-trash"></span>
                                </button>
                            </>
                        }
                        <button className="btn btn-sm btn-light border mx-2 py-0" title="Detalles..."
                            onClick={() => { setSingle(row) }}>
                            <span className="fas fa-eye"></span>
                        </button>
                    </>
                );
            } },
            /* { name: "ID", compact: true , selector: row => row.id, sortable: true,
                format: row => String(row.id).padStart(5, '0')
            }, */
            { name: <div>CÓDIGO INMUEBLE</div>, compact: true , selector: row => row.codigo, sortable: true },
            { name: <div>FECHA DE CARGA INMUEBLE</div>, compact: true , selector: row => row.fecha, sortable: true,
                format: row => moment(row.fecha).format("MM-DD-YYYY"), wrap: true
            },
        ];

        campos.forEach(item => {
            columns.push({name: <div>{item.etiqueta}</div>, compact: true , 
                selector: row => { 
                        return row.datos.find(it => it.campo.nombre === item.nombre)?.valor
                }, 
                sortable: true, wrap: true,
                width : getWidth(item.tipo)
            });
        })

        const customStyles = {
            headCells: {
                style: { background: '#eee', fontWeight: 'bold', 
                textTransform: 'uppercase' }
            },
            cells: { style: { borderLeft: '1px solid #eee', padding: '0px 3px !important'  } }
        }

        return (
            <DataTable columns={columns} data={state.items} theme="solarized" 
                dense={false} striped={true} customStyles={customStyles}
                noDataComponent="No hay resultados para mostrar..."
                pagination />
        );
    }

    /* const Pagination = ({ pages }) => {
        const { current_page, last_page } = pages;
        
        const getLinks = () => {
            let links = [];
            if(current_page < 2) { links.push({ label: "Anterior", disabled: true, page: current_page, active: false }); }
            else { links.push({ label: "Anterior", disabled: false, page: (current_page - 1), active: false }); }

            for (let i=1; i<=last_page; i++) {
                if(i === current_page) { links.push({ label: String(i), disabled: false, page: i, active: true }); }
                else { links.push({ label: String(i), disabled: false, page: i, active: false }); }
            }

            if(current_page === last_page) { links.push({ label: "Siguiente", disabled: true, page: current_page, active: false }); }
            else { links.push({ label: "Siguiente", disabled: false, page: (current_page + 1), active: false }); }

            return links;
        }

        return (
            <nav aria-label="Page navigation pagination-sm">
                <ul className="pagination">
                    {
                        getLinks().map((link, index) => {
                            return  <li key={index} className={(link.disabled)? "page-item disabled": ((link.active)?"page-item active":"page-item")}>
                                        <button className="page-link" onClick={() => gotoPage(link.page)} >{link.label}</button>
                                    </li>
                        })                        
                    }
                </ul>
            </nav>
        );
    } */

    const SearchBox = () => {

        return (
            <div className="row py-1" style={{ fontSize:'0.8em' }}>
                    {
                        (AuthInfo.tipo === 'admin')? 
                            (
                                <div className="col-md-auto me-2">
                                    <button className="btn btn-sm btn-light py-1 border" onClick={() => addItem()}>
                                        <span className="fas fa-plus pe-2"></span>
                                        Nuevo
                                    </button>
                                </div>
                            )
                        : null
                    }
                <div className="col-md-6 form-group" >
                    <div className="input-group input-group-sm">
                        <span className="input-group-text">Buscar: </span>
                        <select name="campo_name" id="campo_name" className="form-select form-select-sm"
                            value={search.campo_nombre}
                            onChange={(e) => { updateSearch(e) }} >
                            <option value="" >-- Seleccionar --</option>
                            <option value="f_fecha">Fecha</option>
                            <option value="f_codigo">Código</option>
                            {
                                campos.map(item=>(<option key={item.id} value={item.nombre}>{item.etiqueta}</option>))
                            }
                        </select>
                        <input type={(tipo => {
                                switch(tipo){
                                    case "fecha": return "date"; 
                                    case "numero": return "number"; 
                                    default: return "text";
                                }
                            })(search.campo_tipo)} className="form-control" name="nombre" 
                            placeholder="Escriba y presione Enter" size={30}
                            onKeyUp={e => setSearchData(e)} defaultValue={state.search.nombre} onChange={e => e} />
                    </div>
                    <div className="d-flex">
                        {
                            state.ad_search.map(item => {
                                return (
                                    <div key={item.nombre} className="border rounded bg-secondary text-white px-1 m-1">
                                        <b>{item.nombre}</b>: <span>[{item.valor}]</span>
                                        <button className="py-0 px-1" style={{ border:'none', background:'none' }}
                                            onClick={()=> dispatch({type:"del_ad_search_it", valor: item.nombre})}>
                                            <span className="fas fa-xmark text-white"></span>
                                        </button>
                                    </div>
                                );
                            })
                        }                       
                    </div>
                </div>
            </div>
        );
    }

    const addItem = () => {
        setEditItem({
            id:0, codigo:"", lat:"0", lng:"0"
        });
        setShowForm(true);
    }

    const updateItem = (item) => {
        setEditItem({...item, password:"password"});
        setShowForm(true);
    }

    const showDelDialog = (item_id) => {
        setDelValue(item_id);
        setOpenDelDialog(true);
    }

    const deleteItem = async () => {
        return Api.delete(`/api/inmuebles/${delValue}`)
            .then(_ => {
                getItems();
                showSnackBar("success", "¡El ítem fue eliminado!")
            }).catch(e => {
                showSnackBar("success", `Error Code: ${e.response.status}`)
                console.log(e);
            });
    }

    const closeDelDialog = (res) => {
        if(res) { deleteItem(); }
        setOpenDelDialog(false);
        setDelValue(0);
    }

    const DeleteDialog = () => {        
        return (
            <Dialog open={openDelDialog}
                onClose={null}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"¿Eliminar Item?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El item y todo los datos relacionados con el mismo se eliminarán definitivamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDelDialog(true) }>OK</Button>
                    <Button onClick={() => closeDelDialog(false) } autoFocus>Cancelar</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const showSnackBar = (type, message) => {
        setSBInfo(prev => { return {...prev, open: true, type: type, message:message}});
    }

    const SnackBar = () => {
        return (
            <Snackbar 
                anchorOrigin={{ "vertical": "bottom", "horizontal":"right" }}
                open={SBInfo.open}
                autoHideDuration={6000}
                onClose={ (e, reason) => { if(reason === "clickaway") { return; } setSBInfo(prev => { return {...prev, open:false } }); } }
                key={ "center + top" }
                TransitionProps={{ appear: false }}
                >
                <Alert severity={SBInfo.type} >{SBInfo.message}</Alert>
            </Snackbar>
        );
    }

    const FormHeader = ({ title }) => {
        return (
            <div className="form-header">
                <div className="p-1">
                    <span className="fas fa-inbox px-2"></span>
                    { title }
                </div>
                <button className="btn btn-sm py-0 px-3 m-1 btn-danger"
                    onClick={ () => setSingle(null) }>
                    <span className="fas fa-xmark"></span>
                </button>
            </div> 
        );
    }

    const CarouselComp = ({ imgs }) => {
        return (
            <Carousel showArrows={true} showThumbs={false} centerMode={true}
                centerSlidePercentage={100} autoPlay={true} >
                {
                    (imgs.length === 0)? 
                        (<div>
                            <img alt="carousel_img" src={`${api_base_url}/storage/imgs/no-image.jpg`} style={{ maxWidth: 'min(400px, 100%)', maxHeight: 'min(500px, 100%)' }} />
                        </div>)
                    :
                    imgs.map((in_imagen) => {
                        return (
                            <div >
                                <img alt="carousel_img" src={`${api_base_url}/storage/${in_imagen.imagen}`} style={{ width: '400px', maxHeight: '500px' }} />
                            </div>
                        )
                    })
                }
            </Carousel>
        );
    }

    const SingleView = () => {
        return (
            <div className="container-form">
                <div className="form w-75" >
                    <FormHeader title="Detalles..." />
                    <div className="py-2 px-4 ">
                        <div className="row" >
                            <div className="col-md-6">
                               { <CarouselComp imgs={single.imagenes} />}
                            </div>
                            <div className="col-md" style={{ maxHeight: '450px', overflowY: 'auto' }}>
                                <table className="table table-sm table-bordered">
                                <tbody>
                                    {/* <tr>
                                        <td className="col-md-5">
                                            ID
                                        </td>
                                        <td>
                                            {String(single.id).padStart(5, '0')}
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td className="col-md-5">
                                            Código
                                        </td>
                                        <td>{single.codigo}</td>
                                    </tr>
                                    {
                                        campos.map(campo => {
                                            if(!campo.es_publico) { return null; }
                                            let dato = single.datos.find(dato => dato.campo.id === campo.id);
                                            if(dato) {
                                                return (
                                                    <tr key={campo.id}>
                                                        <td>{campo.etiqueta}</td>
                                                        <td>
                                                            <div style={{ maxWidth: '400px' }}>
                                                                {dato.valor}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else { 
                                                return (
                                                    <tr key={campo.id}>
                                                        <td>{campo.etiqueta}</td>
                                                        <td>-</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }

                                    <tr>
                                        <td></td>
                                        <td>
                                            <a href={`${api_base_url}/api/inmuebles/${single.id}/download-info`} target="_blank" rel="noreferrer" className="btn btn-sm btn-light border py-0">
                                                <span className="fas fa-download me-1"></span>
                                                Descargar...
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container main-content">
            <div className="py-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" >
                                <span className="fas fa-home pe-1"></span>
                                Principal
                            </Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">Inmuebles</li>
                    </ol>
                </nav>
            </div>
            
            <div>
                <SearchBox />
                { (showForm) && <Form item={editItem} setShowForm={setShowForm} 
                    refreshTable={ () => getItems() } showSnackBar={showSnackBar} campos={campos} 
                    getDefaultData={getDefaultData}
                    /> }
                {
                    (single) && <SingleView inmueble={single} />
                }
                <Table />
                {/* <Pagination pages={state.pages} /> */}
                <DeleteDialog />
                <SnackBar />
            </div>
        </div>
    );
}

export default InmueblesMain;