import { Link } from "react-router-dom";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";
import { useApi } from "../common/Api";
import { CSVLink } from "react-csv";
import { useState, useEffect } from "react";

const UploadInfo = () => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const [wdata , setWData ] = useState([]);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);

    const [loadingCsv, setLoadingCsv] = useState(false);
    const [loadingImgs, setLoadingImgs] = useState(false);

    useEffect(() => {
        set_csv();
        // eslint-disable-next-line
    }, []);

    const set_csv = async() => {
        let campos = await get_campos();
        const headers = ["codigo","latitud","longitud"];
        campos.forEach(cmp => {
            headers.push(cmp.nombre);
        });

        setWData([headers]);
    }

    const get_campos = async () => {
        let campos = [];
        try {
            const response = await Api.get(`api/campos`, { params: { todos: 'si' } });
            campos = response.data;
        } catch (error) { console.log(error); }
        return campos;
    }

    const onChangeFile = (e) => {
        if(e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    }

    const onChangeFile2 = (e) => {
        if(e.target.files) {
            setFile2(e.target.files);
        }
    }

    const form_submit = async (e) => {
        e.preventDefault();

        setLoadingCsv(true);
        let fd = new FormData();
        fd.append("archivo", file);

        await Api.post(`/api/inmuebles/upload-info`, fd, {
            headers: { 'Content-Type': 'multipart/form-data'}
        }).then(_ => { setFile(null); }).catch(_ => { });

        setLoadingCsv(false);
    }

    const form_submit2 = async(e) => {
        e.preventDefault();

        setLoadingImgs(true);
        let fd = new FormData();

        Array.from(file2).forEach((fi, index) => {
            let filename = fi.name.split(".")[0];
            fd.append(`img_${filename}`, fi);
        });

        await Api.post(`/api/inmuebles/upload-images`, fd, {
            headers: { 'Content-Type': 'multipart/form-data'}
        }).then(_ => { setFile(null); }).catch(_ => { });

        setLoadingImgs(false);
    }

    return (
        <div className="container main-content">
            <div className="py-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" >
                                <span className="fas fa-home pe-1"></span>
                                Principal
                            </Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">Subir información</li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-md-5 mx-1 px-3 mt-3 border">
                    {
                        (wdata)? 
                        <CSVLink data={wdata} separator={";"} filename="inmuebles_plantilla.csv" className="btn btn-link">
                            <span className="fas fa-table me-2"></span>
                            Descargar archivo Excel de plantilla.
                        </CSVLink> : null
                    }
                </div>

                <div className="col-md mx-1 py-3 px-3 mt-3 border">
                    <p className="fw-bold">Subir archivo de plantilla con los datos a subir.</p>
                    <div>
                        <ul>
                            <li>Debe llenar la plantilla con los datos en la tabla.</li>
                            <li>Las coordenadas de ubicación(latitud y longitud) deben ser escritas con un punto como separador decimal <b>(Ej. -17.562893)</b>.</li>
                            <li>El documento debe ser guardado en Excel con el formato <b>CSV (delimitado por comas)</b> y con el formato de celdas en <b>texto</b>, para que las coordenadas sean reconocidas correctamente.</li>
                            <li>El documento puede ser guardado en una version igual o superior de Microsoft Excel 2007.</li>
                        </ul>
                    </div>
                    <form onSubmit={e => form_submit(e)} action="">
                        <input type="file" name="archivo" className="form-control form-control-sm" 
                            accept="application/csv, text/csv" 
                            onChange={(e) => onChangeFile(e) }
                            required />
                        {
                            (loadingCsv)? 
                            <div className="blinking">Cargando CSV...</div>
                            : null
                        }
                        <button className="btn btn-sm btn-light border mt-2 px-4">Enviar...</button>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 mx-1 px-3 mt-1"></div>
                <div className="col-md mx-1 py-3 px-3 mt-2 border">
                    <p className="fw-bold">Subir imagenes de inmuebles.</p>
                    <form onSubmit={e => form_submit2(e)} action="">
                        <input type="file" name="archivo_imgs" className="form-control form-control-sm" 
                            accept="image/png, image/gif, image/jpeg" 
                            onChange={(e) => onChangeFile2(e) }
                            required multiple />
                        {
                            (loadingImgs)? 
                            <div className="blinking">Cargando imagenes...</div>
                            : null
                        }
                        <button className="btn btn-sm btn-light border mt-2 px-4">Enviar...</button>
                    </form>
                </div>
            </div>
            
        </div>
    );
}

export default UploadInfo;