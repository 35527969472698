import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from '../Dashboard';
import NotFound from './NotFound';
import Login from '../usuarios/Login';
import Main from '../Main';
import UsuariosMain from '../usuarios/UsuariosMain';
import CamposMain from '../campos/CamposMain';
import InmueblesMain from '../inmuebles/InmueblesMain';
import InmueblesMap from '../inmuebles/InmueblesMap';
import UploadInfo from '../inmuebles/UploadInfo';

import CrmCamposMain from '../crm/crm_campos/CrmCamposMain';
import RegistrosMain from '../crm/registros/RegistrosMain';

import { AuthApi } from './AuthApi';
import { useState } from 'react';
import { readAuthCookie } from "./AuthManager";

const AppRoutes = () => {
	const [AuthInfo, setAuthInfo] = useState(() => readAuthCookie());

	const ProtectedRoute = ({ children }) => {
		if(AuthInfo.isLogged) { return (<>{ children }</>); } 
		else { return (<Navigate to="/login" />); }
	}

	const UnProtectedRoute = ({ children }) => {
		if(!AuthInfo.isLogged) { return (<>{ children }</>); } 
		else { return (<Navigate to="/" />); }
	}

    return (
		<AuthApi.Provider value={{ AuthInfo, setAuthInfo }}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}  >
						<Route index element={<Main />} />
						<Route path='usuarios' element={<ProtectedRoute><UsuariosMain></UsuariosMain></ProtectedRoute>} />
						<Route path='campos' element={<ProtectedRoute><CamposMain /></ProtectedRoute>} />
						<Route path='inmuebles' element={<ProtectedRoute><InmueblesMain /></ProtectedRoute>} />
						<Route path='mapa' element={<ProtectedRoute><InmueblesMap /></ProtectedRoute>} />
						<Route path='upload-info' element={<ProtectedRoute><UploadInfo /></ProtectedRoute>} />
						<Route path='crm-campos' element={<ProtectedRoute><CrmCamposMain/></ProtectedRoute>} />
						<Route path='crm-registros' element={<ProtectedRoute><RegistrosMain/></ProtectedRoute>} />
					</Route>
					<Route exact path="/login" element={<UnProtectedRoute><Login /></UnProtectedRoute>}  />
					<Route path="*" element={<NotFound />}  />
				</Routes>
			</BrowserRouter>
		</AuthApi.Provider>
    );
};

export default AppRoutes;