import { useCrmCampos } from './useCrmCampos';
import { Link } from 'react-router-dom';
import Table from './Table';
import Form from './Form';
import { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar, Alert } from "@mui/material";

const CrmCamposMain = () => {
    const CrmCampos = useCrmCampos();
    const [data, setData] = useState([]);
    const headers = ['ID','NOMBRE','ETIQUETA','TIPO','REQUERIDO','DEPENDENCIA'];

    const [currentItem, setCurrentItem] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const [SBInfo, setSBInfo] = useState({open: false, type: "error", message: ""});
    const [delValue, setDelValue] = useState(0);
    const [openDelDialog, setOpenDelDialog] = useState(false);

    useEffect(() => {
        function getData() { return CrmCampos.get(); }

        getData().then(response => {
            if(response.data?.data !== undefined) {
                setData(response.data.data);
            }
        }).catch(err => console.log(err));

        return () => { console.log("Desmontando componente CrmCamposMain."); }
        // eslint-disable-next-line
    }, [])

    /* const getDataA = () => {
        CrmCampos.get()
            .then(response => { if(response.data !== undefined) { setData(response.data); } })
            .catch(err => console.log(err));
    } */

    const saveItem = useCallback(async (item) => {
        setFormErrors({});
        if(item.id) {
            try {
                await CrmCampos.update(item.id, item);
                setData(prev => prev.map(it => {
                    if(it.id === currentItem.id) { return item; }
                    else { return it; }
                }));

            } catch (err) { setFormErrors(err.response.data); }
            /* CrmCampos.update(item.id, item)
                .then(_ => {
                    setData(prev => prev.map(it => {
                            if(it.id === currentItem.id) { return item; }
                            else { return it; }
                        }));
                })
                .catch(err => setFormErrors(err.response.data)); */
        } else {
            try {
                const response = await CrmCampos.create(item); 
                item.id = response.data?.id;
                setData(prev =>  [...prev, item]) 
            } catch (err) {
                setFormErrors(err.response.data)  
            }
            /* CrmCampos.create(item)
                .then(response => { 
                    item.id = response.data?.id;
                    setData(prev =>  [...prev, item]) 
                })
                .catch(err => setFormErrors(err.response.data)); */
        }

        showSnackBar("success", "¡Operación completada!")
        /* getDataA(); */
    }, [CrmCampos, currentItem])

    const cancelEdit = useCallback(() => {
        setCurrentItem(null);
    }, [])
 
    const newItem = useCallback(() => {
        setFormErrors({});

        const item = { id:0, nombre: "", etiqueta: "", tipo: "texto", requerido: false, dependencia: null, crm_subcampos: [] };
        setCurrentItem(item);
    }, [])

    const editItem = useCallback((item) => {
        setFormErrors({});
        setCurrentItem(item);
    }, [])

    const delItem = useCallback((id) => {
        CrmCampos.destroy(id)
                .then(_ => { 
                    showSnackBar("success", "¡Operación completada!");
                    setData(prev => prev.filter(item => item.id !== id));
                })
                .catch(err => setFormErrors(err.response.data));
    }, [setData, setFormErrors, CrmCampos])

    /* const MemoTable = (props) => {
        const {data} = props; 

        console.log("Renderizando tabla.===================");
        return (
            <Table 
                headers={headers} 
                data_info={data} 
                editItem={editItem}
                showDelDialog={showDelDialog}
            >
            </Table>
        )
    } */

    const showSnackBar = (type, message) => {
        setSBInfo(prev => { return {...prev, open: true, type: type, message:message}});
    }

    const SnackBar = () => {
        return (
            <Snackbar 
                anchorOrigin={{ "vertical": "bottom", "horizontal":"right" }}
                open={SBInfo.open}
                autoHideDuration={6000}
                onClose={ (e, reason) => { if(reason === "clickaway") { return; } setSBInfo(prev => { return {...prev, open:false } }); } }
                key={ "center + top" }
                TransitionProps={{ appear: false }}
                >
                <Alert severity={SBInfo.type} >{SBInfo.message}</Alert>
            </Snackbar>
        );
    }

    const DeleteDialog = () => {        
        return (
            <Dialog open={openDelDialog}
                onClose={null}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"¿Eliminar Item?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El item y todo los datos relacionados con el mismo se eliminarán definitivamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDelDialog(true) }>OK</Button>
                    <Button onClick={() => closeDelDialog(false) } autoFocus>Cancelar</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const showDelDialog = useCallback((item_id) => {
        setDelValue(item_id);
        setOpenDelDialog(true);
    }, [setDelValue, setOpenDelDialog]);

    const closeDelDialog = (res) => {
        if(res) { delItem(delValue); }
        setOpenDelDialog(false);
        setDelValue(0);
    }

    return (
        <div className="container main-content">
            <div className="py-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" >
                                <span className="fas fa-home pe-1"></span>
                                Principal
                            </Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">CRM Campos</li>
                    </ol>
                </nav>
            </div>
            {
                (currentItem)? 
                    (<Form 
                        currentItem={currentItem} 
                        saveItem={saveItem} 
                        cancelEdit={cancelEdit}
                        formErrors={formErrors}
                    />) 
                : null
            }
            <div>
                <div>
                    <button type='button' className="btn btn-sm btn-light border my-1" onClick={newItem}>
                        <span className="fas fa-plus me-1"></span>
                        Agregar...
                    </button>
                </div>
                <Table 
                    headers={headers} 
                    data={data} 
                    editItem={editItem}
                    showDelDialog={showDelDialog}
                >
                </Table>
            </div>
            <DeleteDialog />
            <SnackBar />
        </div>
    );
}

export default CrmCamposMain;