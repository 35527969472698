import { useApi } from "../common/Api";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";
import { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar, Alert } from "@mui/material";
import { Link } from "react-router-dom";

import Form from "./Form";

const UsuariosMain = () => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);
    const [items, setItems] = useState([]);
    const [pages, setPages] = useState({});
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({nombre:"", tipo: "", page: 1});
    const [showForm, setShowForm] = useState(false);
    const [editItem, setEditItem] = useState({});

    const [openDelDialog, setOpenDelDialog] = useState(false);
    const [delValue, setDelValue] = useState(0);

    const [SBInfo, setSBInfo] = useState({open: false, type: "error", message: ""});

    useEffect(() => {
        getItems();
        // eslint-disable-next-line
    }, [search]);

    const setSearchData = (e) => {
        if(e.target.name === "nombre") {
            if(e.code === "Enter" || e.code === "NumpadEnter") {
                setSearch((prev) => { return {...prev, [e.target.name]: e.target.value, page: 1 } });
            }
        }
        if(e.target.name === "tipo") {
            setSearch((prev) => { return {...prev, [e.target.name]: e.target.value, page: 1} });
        }
    }

    const gotoPage = async (noPage) => {
        setItems([]);
        setLoading(true);
        setSearch((prev) => { return {...prev, page: noPage } });
        getItems();
        setLoading(false);
    }

    const getItems = async () => {
        /* setItems([]); */
        setLoading(true);

        await Api.get('/api/usuarios', {params: search}).then(response => {
            const { data, current_page, last_page } = response.data;
            setItems(data);
            setPages({ current_page, last_page  });
        })

        setLoading(false);
    }

    const Row = ({ data }) => {
        return (
            <tr>
                <td style={{ width: "100px" }}>
                    <button className="btn btn-sm btn-light border mx-2 py-0"
                        onClick={() => { updateItem(data); }}>
                        <span className="fas fa-pen"></span>
                    </button>
                    <button className="btn btn-sm btn-danger py-0"
                        onClick={() => { showDelDialog(data.id) }}>
                        <span className="fas fa-trash"></span>
                    </button>
                </td>
                <td>{data.id}</td>
                <td>{data.nombre}</td>
                <td>
                    { (data.tipo === 'admin') && <span>Administrador</span> }
                    { (data.tipo === 'usuario') && <span>Vendedor</span> }
                </td>
                <td>{data.email}</td>
                <td>{data.contacto}</td>
                <td>
                    {
                        (data.activo)? 
                            <span>
                                <span className="fas fa-circle pe-2 text-success" style={{ fontSize: '0.9em' }}></span>
                                Activo
                            </span>: 
                            <span>
                                <span className="fas fa-circle pe-2 text-danger" style={{ fontSize: '0.9em' }}></span>
                                Inactivo
                            </span>
                    }
                </td>
            </tr>
        );
    }

    const Table = () => {
        return (
            <div className="table-responsive">
                <table className="table table-sm table-stripped table-bordered">
                    <thead>
                        <tr>
                            <th style={{ width: "100px" }}></th>
                            <th>ID</th>
                            <th>NOMBRE</th>
                            <th>TIPO</th>
                            <th>EMAIL</th>
                            <th>TELÉFONO</th>
                            <th>ACTIVO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (loading && (items.length === 0)) && <tr><td colSpan={20} className="p-2">Cargando...</td></tr>
                        }
                        {(!loading && (items.length === 0)) &&
                            <tr><td colSpan={20} className="p-2">No hay resultados.</td></tr>
                        }
                        {
                            items.map(item => {
                                return (<Row data={item} key={item.id} />)
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }

    const Pagination = ({ pages }) => {
        const { current_page, last_page } = pages;
        
        const getLinks = () => {
            let links = [];
            if(current_page < 2) { links.push({ label: "Anterior", disabled: true, page: current_page, active: false }); }
            else { links.push({ label: "Anterior", disabled: false, page: (current_page - 1), active: false }); }

            for (let i=1; i<=last_page; i++) {
                if(i === current_page) { links.push({ label: String(i), disabled: false, page: i, active: true }); }
                else { links.push({ label: String(i), disabled: false, page: i, active: false }); }
            }

            if(current_page === last_page) { links.push({ label: "Siguiente", disabled: true, page: current_page, active: false }); }
            else { links.push({ label: "Siguiente", disabled: false, page: (current_page + 1), active: false }); }

            return links;
        }

        return (
            <nav aria-label="Page navigation pagination-sm">
                <ul className="pagination">
                    {
                        getLinks().map((link, index) => {
                            return  <li key={index} className={(link.disabled)? "page-item disabled": ((link.active)?"page-item active":"page-item")}>
                                        <button className="page-link" onClick={() => gotoPage(link.page)} >{link.label}</button>
                                    </li>
                        })                        
                    }
                </ul>
            </nav>
        );
    }

    const SearchBox = () => {
        return (
            <div className="d-flex py-1" style={{ fontSize:'0.8em' }}>
                <div className="me-2">
                    <button className="btn btn-sm btn-light py-1 border" onClick={() => addItem()}>
                        <span className="fas fa-plus pe-2"></span>
                        Nuevo
                    </button>
                </div>
                <div className="form-group">
                    <div className="input-group input-group-sm">
                        <span className="input-group-text">Buscar: </span>
                        <input type="text" className="form-control" name="nombre" 
                            placeholder="Escriba y presione Enter" size={30}
                            onKeyUp={e => setSearchData(e)} defaultValue={search.nombre} onChange={e => e} />
                    </div>
                </div>
                <div className="form-group mx-2">
                    <div className="input-group input-group-sm">
                        <span className="input-group-text">Tipo: </span>
                        <select name="tipo" id="tipo" className="form-select form-select-sm"
                            onChange={e => setSearchData(e) } value={search.tipo}>
                            <option value="">Todos</option>
                            <option value="admin">Administradores</option>
                            <option value="usuario">Usuarios</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    const addItem = () => {
        setEditItem({
            id:0, nombre:"", tipo:"usuario", email:"", password:"password", contacto:"", activo: true
        });
        setShowForm(true);
    }

    const updateItem = (item) => {
        setEditItem({...item, password:"password"});
        setShowForm(true);
    }

    const showDelDialog = (item_id) => {
        setDelValue(item_id);
        setOpenDelDialog(true);
    }

    const deleteItem = async () => {
        return Api.delete(`/api/usuarios/${delValue}`)
            .then(_ => {
                getItems();
                showSnackBar("success", "¡El ítem fue eliminado!")
            }).catch(e => {
                showSnackBar("success", `Error Code: ${e.response.status}`)
                console.log(e);
            });
    }

    const closeDelDialog = (res) => {
        if(res) { deleteItem(); }
        setOpenDelDialog(false);
        setDelValue(0);
    }

    const DeleteDialog = () => {        
        return (
            <Dialog open={openDelDialog}
                onClose={null}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"¿Eliminar Item?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El item y todo los datos relacionados con el mismo se eliminarán definitivamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDelDialog(true) }>OK</Button>
                    <Button onClick={() => closeDelDialog(false) } autoFocus>Cancelar</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const showSnackBar = (type, message) => {
        setSBInfo(prev => { return {...prev, open: true, type: type, message:message}});
    }

    const SnackBar = () => {
        return (
            <Snackbar 
                anchorOrigin={{ "vertical": "bottom", "horizontal":"right" }}
                open={SBInfo.open}
                autoHideDuration={6000}
                onClose={ (e, reason) => { if(reason === "clickaway") { return; } setSBInfo(prev => { return {...prev, open:false } }); } }
                key={ "center + top" }
                TransitionProps={{ appear: false }}
                >
                <Alert severity={SBInfo.type} >{SBInfo.message}</Alert>
            </Snackbar>
        );
    }

    return (
        <div className="container main-content">
            <div className="py-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" >
                                <span className="fas fa-home pe-1"></span>
                                Principal
                            </Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">Usuarios</li>
                    </ol>
                </nav>
            </div>
            <div>
                <SearchBox />
                { (showForm) && <Form item={editItem} setShowForm={setShowForm} 
                    refreshTable={ () => getItems() } showSnackBar={showSnackBar} /> }
                <Table />
                <Pagination pages={pages} />
                <DeleteDialog />
                <SnackBar />
            </div>
        </div>
    );
}

export default UsuariosMain;