import { Link } from "react-router-dom";
import { GoogleMap, LoadScript, MarkerF, InfoWindowF } from "@react-google-maps/api";
import pin from "../imgs/pin.png";
import { useAuthManager } from "../common/AuthManager";
import { useAuth } from "../common/AuthApi";
import { useApi, api_base_url } from "../common/Api";
import { useState, useEffect, memo } from "react";
import { Carousel } from "react-responsive-carousel";
import { MapContainer, TileLayer, useMap, Popup, Marker } from 'react-leaflet'
import L from 'leaflet';

const InmueblesMap = () => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const [items, setItems] = useState([]);

    useEffect(() => {
        if(!items.lenght){ 
            getInmuebles();
            // eslint-disable-next-line
         }
         // eslint-disable-next-line
    }, []);
    
    const getInmuebles = () => {
        Api.get(`${api_base_url}/api/inmuebles?todos=si`)
            .then(response => {
                const {data} = response;
                setItems(data.data);
            }).catch(_=> {});
        
    }

    const Markers = ({inmuebles}) => {
        const [ selected, setSelected ] = useState({});

        const onSelect = item => {
            setSelected(item);
        }

        const TableInmueble = () => {
            return (
                <div style={{ maxWidth: '360px' }}>
                    <div className="mx-1 pb-1">
                        <Carousel showArrows={true} showThumbs={false} centerMode={true}
                            centerSlidePercentage={100} autoPlay={true} >
                            {
                                (!selected.imagenes.length)? 
                                    (
                                        <div>
                                            <img className="" 
                                                src={`${api_base_url}/storage/imgs/no-image.jpg`} alt="" 
                                                style={{ height: '200px' }} />
                                        </div>
                                    )
                                :
                                selected.imagenes.map(img => {
                                    return (
                                        <div>
                                            <img className="" src={`${api_base_url}/storage/${img.imagen}`} alt="" style={{ height: '200px' }} loading="lazy" />
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    <table className="table table-sm table-striped table-bordered">
                        <tbody>
                            {/* <tr>
                                <td>ID</td>
                                <td>{ String(selected.id).padStart(5, '0') }</td>
                            </tr> */}
                            <tr>
                                <td>Código</td>
                                <td>{ selected.codigo }</td>
                            </tr>
                            <tr>
                                <td>Ubicación</td>
                                <td>{ `${selected.lat?? 0 }, ${selected.lng?? 0}` }</td>
                            </tr>

                            {
                                selected.datos?.map(dato => {
                                    if(!dato.campo.es_publico) { return null; }
                                    return (
                                        <tr key={dato.id}>
                                            <td style={{ width: '30%' }}>
                                                <div >
                                                    {dato.campo.etiqueta}
                                                </div>
                                            </td>
                                            <td>{dato.valor}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <>
                {
                    inmuebles.map((item, index) => {
                        return (
                            <MarkerF 
                                key={`marker_${index}`}
                                position={{ lat: item.lat, lng: item.lng }}
                                icon={pin}
                                item={item}
                                title="Detalles..."
                                /* onClick={ () => { onClickMarker(item); }} */
                                onClick={() => { onSelect(item); }}
                                />
                        )
                    })
                }

                {
                    selected.lat && 
                    (
                        <InfoWindowF
                            position={{ lat: selected.lat, lng: selected.lng }}
                            clickable={true}
                            onCloseClick={() => setSelected({})}
                        >
                            <TableInmueble />
                        </InfoWindowF>
                    )
                }
            </>
        )
    }

    const MapComp2 = ({inmuebles}) => {

        const containerStyle = {
            width: "100%",
            height: "500px",
        };
        
        const [center, setCenter] = useState({lat: -17.382080, lng: -66.159938});

        return (
            <div className="row">
                <div className="col-md">
                    <LoadScript
                        googleMapsApiKey="AIzaSyCr2XsjbhzZZHK9Zfst6BsYZtUgdeLBS04"
                    >
                        <GoogleMap 
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={16}
                            onCenterChanged={ e => { console.log(e); }}
                        >
                            <Markers inmuebles={inmuebles} />
                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>
        );
    }

    const Markers2 = ({inmuebles}) => {

        const TableInmueble = ({selected}) => {
            return (
                <div style={{ maxWidth: '360px', maxHeight: '400px', overflowY: 'auto' }}>
                    <div className="mx-1 pb-1">
                        <Carousel showArrows={true} showThumbs={false} centerMode={true}
                            centerSlidePercentage={100} autoPlay={true} >
                            {
                                (!selected.imagenes.length)? 
                                    (
                                        <div>
                                            <img className="" 
                                                src={`${api_base_url}/storage/imgs/no-image.jpg`} alt="" 
                                                style={{ height: '200px' }} />
                                        </div>
                                    )
                                :
                                selected.imagenes.map(img => {
                                    return (
                                        <div>
                                            <img className="" src={`${api_base_url}/storage/${img.imagen}`} alt="" style={{ height: '200px' }} loading="lazy" />
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    <table className="table table-sm table-striped table-bordered">
                        <tbody>
                            {/* <tr>
                                <td>ID</td>
                                <td>{ String(selected.id).padStart(5, '0') }</td>
                            </tr> */}
                            <tr>
                                <td>Código</td>
                                <td>{ selected.codigo }</td>
                            </tr>
                            <tr>
                                <td>Ubicación</td>
                                <td>{ `${selected.lat?? 0 }, ${selected.lng?? 0}` }</td>
                            </tr>

                            {
                                selected.datos?.map(dato => {
                                    if(!dato.campo.es_publico) { return null; }
                                    return (
                                        <tr key={dato.id}>
                                            <td style={{ width: '30%' }}>
                                                <div >
                                                    {dato.campo.etiqueta}
                                                </div>
                                            </td>
                                            <td>{dato.valor}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td></td>
                                <td>
                                    <a href={`${api_base_url}/api/inmuebles/${selected.id}/download-info`} target="_blank" className="btn btn-sm btn-light border py-0">
                                        <span className="fas fa-download me-1"></span>
                                        Descargar...
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        const my_icon = new L.icon({
            iconUrl: pin,
            iconRetinaUrl: pin,
            popupAnchor:  [-0, -0],
            iconSize: [32,52], 
        })

        return (
            <>
                {
                    inmuebles.map((item, index) => {
                        return (
                            <Marker 
                                key={`marker_${index}`} 
                                position={{ lat: item.lat, lng: item.lng }}
                                icon={my_icon}
                                item={item}
                                title="Detalles..."
                                /* onClick={() => { onSelect(item); }} */
                            >
                                <Popup>
                                    <TableInmueble selected={item} />
                                </Popup>
                            </Marker>
                        )
                    })
                }
            </>
        );
    } 

    const MapComp = ({inmuebles}) => {
        const [center, setCenter] = useState({lat: -17.382080, lng: -66.159938});


        return (
            <div>
                <MapContainer center={center} zoom={12} scrollWheelZoom={false} style={{ height: '90vh', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Markers2 inmuebles={inmuebles} />
                </MapContainer>
            </div>
        );
    }

    const MemoMapComp = memo(({inmuebles}) => {
        return (<MapComp inmuebles={inmuebles} />);
    });

    

    return (
        <div className="container-fluid main-content">
            <div className="pt-2 container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/" >
                                <span className="fas fa-home pe-1"></span>
                                Principal
                            </Link>
                        </li>
                        <li className="breadcrumb-item active fw-bold" aria-current="page">Mapa</li>
                    </ol>
                </nav>
            </div>

            <div>
                <MemoMapComp inmuebles={items} />
            </div>
        </div>
    );
}

export default InmueblesMap;