import moment from "moment/moment";
import { useAuth } from "../../common/AuthApi";
import { useState, useEffect, memo } from "react";

const Table = (props) => {
    const { AuthInfo } = useAuth();
    const {headers, data, editItem, showDelDialog, campos, loading} = props;
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        if(data !== undefined) {
            setCurrentData(data);
        }

        return () => { console.log("Desmontando componente Table."); }
    }, [data]);

    const MemoRow = memo(({row}) => {
        return (
            <tr key={row.id}>
                <td className="text-center" style={{ width: '100px' }}>
                    <button className="btn btn-sm btn-light border py-0 mx-2"
                        onClick={() => editItem(row)}>
                        <span className="fas fa-pen"></span>
                    </button>
                    {
                        (AuthInfo.tipo === "admin")? 
                            (<button className="btn btn-sm btn-danger py-0"
                                onClick={() => showDelDialog(row.id)}>
                                <span className="fas fa-trash"></span>
                            </button>) : null
                    }
                </td>
                <td className="text-end" style={{ width: '80px' }}>
                    {String(row.id).padStart(5, '0')}
                </td>
                <td className="text-center">{ moment(row.fecha).format('DD/MM/YYYY')}</td>
                <td>{ row.usuario?.nombre }</td>
                {
                    campos.map(campo => {
                        let data = row.datos.find(dt => dt.crm_campo_id === campo.id);
                        return (data)? (<td key={campo.id}>{data.dato}</td>) : (<td key={campo.id}>-</td>)
                    })
                }
            </tr>
        );
    });

    const TableHeader = memo(() => {
        return (
            <tr>
                <th></th>
                {
                    headers.map(header => {
                        return (<th key={header}>{header}</th>);
                    })
                }
            </tr>
        )
    })

    return (
        <div className="table-responsive">
            <table className="table table-sm table-bordered">
                <thead>
                    <TableHeader />
                </thead>
                <tbody>
                    {
                        (loading && !currentData.length)? 
                            (<tr><td colSpan={50} className="py-2">Cargando...</td></tr>)
                            :null
                    }
                    {
                        (!currentData.length && !loading)?
                            (<tr><td colSpan={50} className="py-2">No hay resultados...</td></tr>)
                        : currentData?.map(row => {
                            return (<MemoRow row={row} key={row.id} />)
                        })
                    }
                </tbody>
            </table>
        </div>
    );

}

export default memo(Table);