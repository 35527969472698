import { useApi, api_base_url } from "../../common/Api";
import { useAuthManager } from "../../common/AuthManager";
import { useAuth } from "../../common/AuthApi";

export const useCrmSubCampos = () => {
    const { setAuthInfo } = useAuth();
    const AuthManager = useAuthManager(setAuthInfo);
    const Api = useApi(AuthManager);

    const get = (crm_campo_id) => {
        return Api.get(`${api_base_url}/api/crm_campos/${crm_campo_id}/crm_subcampos`);
    }

    const create = (crm_campo_id, data) => {
        return Api.post(`${api_base_url}/api/crm_campos/${crm_campo_id}/crm_subcampos`, data);
    }

    const update = (crm_campo_id, id, data) => {
        return Api.put(`${api_base_url}/api/crm_campos/${crm_campo_id}/crm_subcampos/${id}`, data);
    }

    const destroy = (crm_campo_id, id) => {
        return Api.delete(`${api_base_url}/api/crm_campos/${crm_campo_id}/crm_subcampos/${id}`);
    }

    return {get, create, update, destroy};
}